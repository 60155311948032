import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@Injectable({
  providedIn: 'root'
})
export class SvgIconService {

  iconPath = '/assets/icons/';

  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    // Note that we provide the icon here as a string literal here due to a limitation in
    // Stackblitz. If you want to provide the icon from a URL, you can use:
    iconRegistry.addSvgIcon('auxilio', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/1_AUXILIO.svg`));
    iconRegistry.addSvgIcon('auxilios', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/2_AUXILIOS.svg`));
    iconRegistry.addSvgIcon('admissao-residente', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/admissao_de_residente.svg`));
    iconRegistry.addSvgIcon('almoco-jantar', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/Almoco_jantar.svg`));
    iconRegistry.addSvgIcon('andador-02-rodas', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/ANDADOR_02_RODAS.svg`));
    iconRegistry.addSvgIcon('andador-03-rodas', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/ANDADOR_03_RODAS.svg`));
    iconRegistry.addSvgIcon('andador-04-rodas', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/ANDADOR_04_RODAS.svg`));
    iconRegistry.addSvgIcon('banho', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/BANHO.svg`));
    iconRegistry.addSvgIcon('bengala', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/BENGALA.svg`));
    iconRegistry.addSvgIcon('cadeira-rodas', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/cadeira_de_rodas.svg`));
    iconRegistry.addSvgIcon('cafe', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/cafe.svg`));
    iconRegistry.addSvgIcon('canudo', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/CANUDO.svg`));
    iconRegistry.addSvgIcon('copo-adaptado', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/COPO_ADAPTADO.svg`));
    iconRegistry.addSvgIcon('cuidador', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/Cuidador.svg`));
    iconRegistry.addSvgIcon('diabetico', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/Diabetico.svg`));
    iconRegistry.addSvgIcon('dieta-dupla', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/DIETA_DUPLA.svg`));
    iconRegistry.addSvgIcon('dieta-enteral', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/DIETA_ENTERAL.svg`));
    iconRegistry.addSvgIcon('dieta-suspensa', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/Dieta_suspensa.svg`));
    iconRegistry.addSvgIcon('espessante-mel', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/Espessante_Mel.svg`));
    iconRegistry.addSvgIcon('espessante-nectar', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/Espessante_Nectar.svg`));
    iconRegistry.addSvgIcon('espessante-pudim', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/Espessante_Pudim.svg`));
    iconRegistry.addSvgIcon('espessante', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/Espessante.svg`));
    iconRegistry.addSvgIcon('falecimento', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/Falecimento.svg`));
    iconRegistry.addSvgIcon('fralda', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/FRALDA.svg`));
    iconRegistry.addSvgIcon('hidratacao', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/hidratacao.svg`));
    iconRegistry.addSvgIcon('hipodermoclise-2', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/HIPODERMOCLISE_2.svg`));
    iconRegistry.addSvgIcon('hipodermoclise', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/HIPODERMOCLISE.svg`));
    iconRegistry.addSvgIcon('independente', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/INDEPENDENTE.svg`));
    iconRegistry.addSvgIcon('isolamento-aereo', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/ISOLAMENTO_AEREO.svg`));
    iconRegistry.addSvgIcon('isolamento-contato-aereo', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/Isolamento_contato_aereo.svg`));
    iconRegistry.addSvgIcon('isolamento-contato', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/ISOLAMENTO_CONTATO.svg`));
    iconRegistry.addSvgIcon('jardim', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/JARDIM.svg`));
    iconRegistry.addSvgIcon('maximove', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/MAXIMOVE.svg`));
    iconRegistry.addSvgIcon('mudanca-decubito', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/MUDANCA_DECUBITO.svg`));
    iconRegistry.addSvgIcon('mudanca-dieta', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/mudanca_dieta.svg`));
    iconRegistry.addSvgIcon('oxigenio', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/Oxigenio.svg`));
    iconRegistry.addSvgIcon('plano-lilas', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/Plano_Lilas.svg`));
    iconRegistry.addSvgIcon('refeicao-2', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/Refeicao_2.svg`));
    iconRegistry.addSvgIcon('refeicao-adaptada', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/refeicao_adaptada.svg`));
    iconRegistry.addSvgIcon('refeicao', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/Refeicao.svg`));
    iconRegistry.addSvgIcon('residente-agressivo', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/Residente_agressivo.svg`));
    iconRegistry.addSvgIcon('residente-alteracao-leve', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/Residente_alteracao_leve.svg`));
    iconRegistry.addSvgIcon('residente-normal', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/Residente_normal.svg`));
    iconRegistry.addSvgIcon('residente', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/Residente.svg`));
    iconRegistry.addSvgIcon('retorno-hospital', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/retorno_de_hospital.svg`));
    iconRegistry.addSvgIcon('risco-fuga', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/risco_de_fuga.svg`));
    iconRegistry.addSvgIcon('risco-queda-1', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/risco_de_queda-1.svg`));
    iconRegistry.addSvgIcon('risco-queda', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/risco_de_queda.svg`));
    iconRegistry.addSvgIcon('ronda', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/Ronda.svg`));
    iconRegistry.addSvgIcon('sarastedy', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/SARASTEDY.svg`));
    iconRegistry.addSvgIcon('soro-1', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/SORO_1.svg`));
    iconRegistry.addSvgIcon('soro-2', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/SORO_2.svg`));
    iconRegistry.addSvgIcon('talher-adaptado-1', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/TALHER_ADAPTADO_1.svg`));
    iconRegistry.addSvgIcon('tratamento-procedimento', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/tratamento_procedimento.svg`));
    iconRegistry.addSvgIcon('troca-oxigenio', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/Troca_de_Oxigenio.svg`));
    iconRegistry.addSvgIcon('visita', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/Visita.svg`));
    iconRegistry.addSvgIcon('rotina', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/rotina.svg`));
    iconRegistry.addSvgIcon('nao-programado', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/nao-programado.svg`));
    iconRegistry.addSvgIcon('handle', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/handle.svg`));
    iconRegistry.addSvgIcon('add-button', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/add-button.svg`));
    // iconRegistry.addSvgIconLiteral('thumbs-up', sanitizer.bypassSecurityTrustHtml(THUMBUP_ICON));
    iconRegistry.addSvgIcon('exclamacao', sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/exclamacao.svg`));

  }

}
