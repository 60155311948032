<div class="ng-container text-center">
<button mat-button type="button" class="btn btn-block" (click)="onBack($event)">
    < Voltar</button>
  
      <h1>Plano de Cuidados</h1>
  
      <div class="scroll">
  
        <ml-patient-header [patient]="selectedPatient">
        </ml-patient-header>

        <p *ngIf="!isLoading"><b>Quarto:</b> {{quarto}} | <b>Bloco:</b> {{bloco}}</p>
        <p *ngIf="!isLoading"><b>Prontuário: </b> {{prontuario}}</p>
        
        <mat-icon class="icons" *ngFor="let icon of patientIcons" aria-hidden="false" [class.enabled]="true"
            [svgIcon]="icon.display" (click)="alerta(icon)">
        </mat-icon>

      </div>
      <ng-container *ngIf="!isLoading">
        <div class="container">
            <h2>USO DE OXIGÊNIO</h2>
            <p *ngIf="!usoOxigenio"><b>Uso: </b>Não</p>
            <div *ngIf="usoOxigenio">
                <p><b>Uso:</b> Sim | <b>Fluxo:</b> {{fluxoOxigenio}}</p>
                <p><b>Período de utilização:</b> {{periodoutilizacaoAlimentacao}}, {{periodoutilizacaoDeambulacao}}, {{periodoutilizacaoNoturno}},
                    {{periodoutilizacaoBanho}}, {{periodoutilizacaoContinuo}}
                </p>
            </div>
            <p *ngIf="cpap"><b>CPAP:</b> {{cpap}}</p>
            <p>{{campoAbertoOxi}}</p>
        </div>
        
        <div class="container">
            <h2>TRANSFERÊNCIA</h2>
            <p><b>Todos os momentos:</b> {{todosOsMomentos}} | <b>Auxílio/dispositivo:</b> {{auxilioDispositivo}}</p>
            <p><b>Sair/Entrar no leito:</b> {{sairEntrarNoLeito}} | <b>Auxílio/dispositivo:</b> {{auxilioDispositivo2}}</p>
            <p><b>Cadeira/Cadeira:</b> {{cadeiraCadeira}} | <b>Auxílio/dispositivo:</b> {{auxilioDispositivo3}}</p>
            <p>{{campoAbertoTransf}}</p>
        </div>
        <div class="container">
            <h2>LOCOMOÇÃO</h2>
            <p><b>Todas as dependências do RIAE:</b> {{todasAsDependencias}} - {{marchas[0]}}</p>
            <p><b>Áreas externas do RIAE:</b> {{areasExternas}} - {{marchas[2]}}</p>
            <p><b>Áreas internas do RIAE:</b> {{areasInternas}} - {{marchas[3]}}</p>
            <p><b>Andar de moradia:</b> {{andarMoradia}}  {{marchas[4]}}</p>
            <p><b>Fora do andar de moradia:</b> {{foraAndarMoradia}}  {{marchas[5]}}</p>
            <p><b>Dentro do quarto:</b> {{dentroQuarto}} - {{marchas[6]}}</p>
            <p>{{campoAbertoLoc}}</p>
        </div>
        <div class="container">
            <h2>VASO SANITÁRIO</h2>
            <p *ngIf="!usoFralda"><b>Uso de fraldas: </b>Não</p>
            <div *ngIf="usoFralda">
                <p><b>Uso de fraldas:</b> {{usoFralda}} | <b>Período: </b>{{periodoFralda}}</p>
                <p><b>Trocas: </b>{{trocas}} | <b>Uso do vaso sanitário: </b>{{vasoSanitario}}</p>
            </div>
            <p>{{campoAbertoEnf}}</p>
        </div>
        <div class="container">
            <h2>BANHO</h2>
            <p *ngIf="!cadeiraHigienica"><b>Uso de cadeira higiênica: </b>Não</p>
            <div *ngIf="cadeiraHigienica">
                <p><b>Uso de cadeira higiênica: </b>{{cadeiraHigienica}} | <b>Auxílio: </b>{{auxilioFono}}</p>
            </div>
            <p>{{campoAbertoFono2}}</p>
        </div>
        <div class="container">
            <h2>HIGIENE PESSOAL</h2>
            <p><b>Higiene pessoal e uso de prótese: </b>{{higieneOralUsoProtese}}</p>
            <p><b>Pentear-se: </b>{{pentear}} | <b>Barbear-se/Maquiar-se: </b>{{barbaMaquiagem}}</p>
        </div>
        
        <div class="container">
            <h2>VESTIR/CALÇAR</h2>
            <p><b>Auxílio: </b>{{auxilioTO}}</p>
            <p><b>Partes Superiores: </b>{{superiores}} | <b>Partes Inferiores: </b>{{inferiores}}</p>
            <p>{{campoAbertoTO}}</p>
        </div>
        <div class="container">
            <h2>COMUNICAÇÃO</h2>
            <p><b>Idoso comunicativo: </b>{{comunicacao}}</p>
            <p>{{campoAbertoFono3}}</p>
        </div>
        <div class="container">
            <h2>COMPORTAMENTO</h2>
            <p>{{comportamento}}</p>
        </div>
        <div class="container">
            <h2>ALIMENTAÇÃO</h2>
            <p><b>Oferta via oral: </b>{{ofertaOral}}</p>
            <p><b>Tipo: </b>{{tipo}}</p>
            <p><b>Local de refeição: </b>{{localRefeicao}} - {{descrevaRefeicao}}</p>
            <p *ngIf="suspender"><b>Suspender dieta: </b>Sim</p>
            <p *ngIf="refeicaoAdaptada"><b>Refeição com adaptação: </b> Sim</p>
            <p *ngIf="!usoEspessante"><b>Uso do espessante: </b>Não</p>
            <div *ngIf="usoEspessante">
                <p><b>Uso do espessante: </b>Sim</p>
                <p><b>Tipo de consistência: </b>{{consistenciaEspessante}}</p>
                <p><b>Proporção do Espessante: </b>{{proporcaoEspessante}}</p>
            </div>
            <p>{{campoAbertoFono}}</p>
        </div>
        
        <div class="container">
            <h2>PREVENÇÃO QUEDAS</h2>
            <p>{{prevencaoQuedas}}</p>
        </div>
        
        <div class="container">
            <h2>OUTRAS ORIENTAÇÕES</h2>
            <p>{{rotinaGeral}}</p>
        </div> 
        <div class="container frase">
            <p>Seguir orientações sobre medidas preventivas de LESÃO POR PRESSÃO na pasta de registro diário.</p>
        </div>
    </ng-container>
    <div *ngIf="isLoading">
       <p>Carregando Plano de Cuidados...</p>
    </div>
</div>