
import { refCount, publishReplay, map, filter, toArray } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { IEstoqueMaterial } from '@medlogic/shared/shared-interfaces';
import { Observable } from 'rxjs';
import { EMPTY } from 'rxjs';
import { GlobalService, LogService, IForm } from '@medlogic/shared/shared-interfaces';
import { CadastroService } from '@medlogic/shared/shared-data-access';

export abstract class NovoEstoqueDeMateriaisService {

  // tslint:disable-next-line: max-line-length
  protected lstVariaveis = 'V_832,V_27987,V_27988,V_27989,V_27993,V_27994,V_27995,V_27997,V_27998,V_28012,V_28014,V_28018,V_28019,V_28020,V_28021,V_28022,V_28023,V_28452,V_29828,V_30267,V_30268,V_30269,V_30296,V_30313,V_30321,V_30339,V_30350,V_30412,V_32855,V_34382,V_34383,V_34718,V_100306,V_100323,V_100851,V_100852,V_100853,V_100854,V_101098,V_101099,V_101213,V_104680';
  private variavelGrid = 'V_28008';
  private lstVariaveisGrid = 'V_832,V_27987,V_27993,V_27994,V_27997,V_27999,V_28000,V_28001,V_28004,V_28012,V_28014,V_28018,V_28023,V_30269,V_30350,V_34687,V_34705,V_34718,V_100306,V_100321,V_100322,V_100325,V_100326,V_100406,V_100853,V_100854,V_101702';

  recurrences: Array<IEstoqueMaterial> = new Array<IEstoqueMaterial>();

  cadastroNo = 24638;
  currentDtInicial = new Date();
  currentDtFinal = new Date();
  cadastrosCache: Observable<any>;


  constructor(
    protected http: HttpClient,
    protected cadastroSrv: CadastroService,
    protected glb: GlobalService,
    protected log: LogService) {
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'constructor', error.message);
    }
  }

  // TODO: Atenção: o correto não é retornar todos os dados, de todas as datas e depois filtrar, mas enviar a data como parâmetro.
  getAll(ano: number, startDate?: Date, endDate?: Date): Observable<IEstoqueMaterial> {
    try {
      this.cadastroNo = ano;
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      return this.getWithCache(this.cadastroNo, startDate, endDate).pipe(
        filter(f => {
          return this.glb.isBetweenIgnoreTime(f.dataAtual, startDate, endDate);
        }));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAll', error.message);
    }
    return null;
  }

  /* Método utilizado para popular uma lista com os itens ativos. */
  loadArray(ano: number): Observable<any> {
    try {
      const propLabel = 'titulo'; const propValue = 'codigo'; const propEnabled = 'habilitado';
      return this.cadastroSrv.loadArray(this.getAll(ano), propLabel, propValue, propEnabled);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'loadArray', error.message);
    }
  }

  /* Limpa o cache de forma que a próxima chamada buscará os dados do serviço novamente. */
  clearCache(): void {
    try {
      this.cadastrosCache = null;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'clearCache', error.message);
    }
  }

  protected getWithCache(cadastroNo: number, startDate: Date, endDate: Date): Observable<IEstoqueMaterial> {
    if (
      (startDate.getTime() !== this.currentDtInicial.getTime())
      || (endDate.getTime() !== this.currentDtFinal.getTime())
      || (!this.cadastrosCache)
    ) {
      this.currentDtInicial = startDate;
      this.currentDtFinal = endDate;
      this.cadastrosCache = this.getFromCadastro(cadastroNo, startDate, endDate);
    } else {
      console.log('retorno do cache');
    }
    return this.cadastrosCache;
  }

  protected getFromCadastro(cadastroNo: number, startDate: Date, endDate: Date): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv
        .getCadastro(cadastroNo, this.lstVariaveis).pipe(
          map(m => this.toAttribute(m)),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadatro', error.message);
    }
    return null;
  }

  /* Converte o objeto que vem do serviço para IVitalSign. */
  protected toAttribute(c: any): IEstoqueMaterial {
    try {
      return {
        ocorrenciaNo: c.OcorrenciaNo,
        dosagem: c.V_832,
        codigo: c.V_27987,
        titulo: c.V_27988,
        habilitado: this.glb.getBoolean(c.V_27989),
        centroCusto: c.V_27993,
        estoque: c.V_27994,
        estoqueMinimo: c.V_27995,
        unidademedida: c.V_27997,
        dataUltimaCompra: c.V_27998,
        preco: c.V_28012,
        fornecedor: c.V_28014,
        tipoMaterial: c.V_28018,
        consumoDiario: c.V_28019,
        dataProximaCompra: c.V_28020,
        hoje: this.glb.ddMMYYYYThhmmssToDate(c.V_28021),
        duracaoTotal: c.V_28022,
        unidadeNegocio: c.V_28023,
        nF: c.V_28452,
        idPaciente: c.V_29828,
        medicamento: c.V_30267,
        medicamentoControlado: c.V_30268,
        medicamento2: c.V_30269,
        codigoPacienteNomePaciente: c.V_30296,
        uniNegocio: c.V_30313,
        codPacienteNomedoPacienteCodMedicamento: c.V_30321,
        tipoMedicamentosCodPaciente: c.V_30339,
        itens: c.V_30350,
        materialID: c.V_30412,
        cascataTipoMAterialCodHosp: c.V_32855,
        concatUniNegocioSIM: c.V_34382,
        concatUniNegocioNAO: c.V_34383,
        quantidadeEmbalagem: c.V_34718,
        idMedicamento: c.V_100306,
        codCenCusto: c.V_100323,
        ultimoLote: c.V_100851,
        dataUltimaValidade: c.V_100852,
        dose: c.V_100853,
        unidadeDose: c.V_100854,
        consumoDiario2: c.V_101098,
        gOTASPMl: c.V_101099,
        dataAtual: this.glb.ddMMYYYYThhmmssToDate(c.V_101213),
        guide: c.V_104680,

     } as IEstoqueMaterial;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'toAttribute', error.message);
    }
    return null;
  }

  /* Insere ou atualiza o item.
    * Se for atualização, especificar o id. Caso contrário, não fornecê-lo.
    */
  save(movimentacao: IEstoqueMaterial, uno: number, id?: number): Observable<any> {
    try {
      const forms: IForm[] = this.mapToForm(movimentacao).filter(f => f.ValorDado);
      return this.cadastroSrv.save(forms, uno, this.cadastroNo, id);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'save', error.message);
    }
    return EMPTY;
  }

  protected mapToForm(estoqueMaterial: IEstoqueMaterial): Array<IForm> {
    try {
        return [
          { VariavelNo: 832, ValorDado: estoqueMaterial.dosagem || '' },
          { VariavelNo: 27987, ValorDado: estoqueMaterial.codigo || '' },
          { VariavelNo: 27988, ValorDado: estoqueMaterial.titulo || '' },
          { VariavelNo: 27989, ValorDado: estoqueMaterial.habilitado ? 'SIM' : 'NÃO' },
          { VariavelNo: 27993, ValorDado: estoqueMaterial.centroCusto || '' },
          { VariavelNo: 27994, ValorDado: estoqueMaterial.estoque || '' },
          { VariavelNo: 27995, ValorDado: estoqueMaterial.estoqueMinimo || '' },
          { VariavelNo: 27997, ValorDado: estoqueMaterial.unidademedida || '' },
          { VariavelNo: 27998, ValorDado: estoqueMaterial.dataUltimaCompra || '' },
          { VariavelNo: 28012, ValorDado: estoqueMaterial.preco || '' },
          { VariavelNo: 28014, ValorDado: estoqueMaterial.fornecedor || '' },
          { VariavelNo: 28018, ValorDado: estoqueMaterial.tipoMaterial || '' },
          { VariavelNo: 28019, ValorDado: estoqueMaterial.consumoDiario || '' },
          { VariavelNo: 28020, ValorDado: estoqueMaterial.dataProximaCompra || '' },
          { VariavelNo: 28021, ValorDado: this.glb.ddMMYYYYThhmmssToDate(estoqueMaterial.hoje) },
          { VariavelNo: 28022, ValorDado: estoqueMaterial.duracaoTotal || '' },
          { VariavelNo: 28023, ValorDado: estoqueMaterial.unidadeNegocio || '' },
          { VariavelNo: 28452, ValorDado: estoqueMaterial.nF || '' },
          { VariavelNo: 29828, ValorDado: estoqueMaterial.idPaciente || '' },
          { VariavelNo: 30267, ValorDado: estoqueMaterial.medicamento || '' },
          { VariavelNo: 30268, ValorDado: estoqueMaterial.medicamentoControlado || '' },
          { VariavelNo: 30269, ValorDado: estoqueMaterial.medicamento || '' },
          { VariavelNo: 30296, ValorDado: estoqueMaterial.codigoPacienteNomePaciente || '' },
          { VariavelNo: 30313, ValorDado: estoqueMaterial.uniNegocio || '' },
          { VariavelNo: 30321, ValorDado: estoqueMaterial.codPacienteNomedoPacienteCodMedicamento || '' },
          { VariavelNo: 30339, ValorDado: estoqueMaterial.tipoMedicamentosCodPaciente || '' },
          { VariavelNo: 30350, ValorDado: estoqueMaterial.itens || '' },
          { VariavelNo: 30412, ValorDado: estoqueMaterial.materialID || '' },
          { VariavelNo: 32855, ValorDado: estoqueMaterial.cascataTipoMAterialCodHosp || '' },
          { VariavelNo: 34382, ValorDado: estoqueMaterial.concatUniNegocioSIM || '' },
          { VariavelNo: 34383, ValorDado: estoqueMaterial.concatUniNegocioNAO || '' },
          { VariavelNo: 34718, ValorDado: estoqueMaterial.quantidadeEmbalagem || '' },
          { VariavelNo: 100306, ValorDado: estoqueMaterial.idMedicamento || '' },
          { VariavelNo: 100323, ValorDado: estoqueMaterial.codCenCusto || '' },
          { VariavelNo: 100851, ValorDado: estoqueMaterial.ultimoLote || '' },
          { VariavelNo: 100852, ValorDado: estoqueMaterial.dataUltimaValidade || '' },
          { VariavelNo: 100853, ValorDado: estoqueMaterial.dose || '' },
          { VariavelNo: 100854, ValorDado: estoqueMaterial.unidadeDose || '' },
          { VariavelNo: 101098, ValorDado: estoqueMaterial.consumoDiario || '' },
          { VariavelNo: 101099, ValorDado: estoqueMaterial.gOTASPMl || '' },
          { VariavelNo: 101213, ValorDado: this.glb.ddMMYYYYThhmmssToDate(estoqueMaterial.dataAtual) },
          { VariavelNo: 104680, ValorDado: estoqueMaterial.guide || '' },
              ];
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapToForm', error.message);
    }
    return null;
  }



  /* Retorna um título bem formatado para cada campo.  */
  getRotulo(field: string): string {
    try {
      const mapR = {
        codigoPaciente: '#NAOEXIBIR#',
        dataavaliacao: '#NAOEXIBIR#',
        codigo: '#NAOEXIBIR#',
        titulo: '#NAOEXIBIR#',
        habilitado: '#NAOEXIBIR#',
        laudo: 'Laudo',
        oCORRENCIA: '#NAOEXIBIR#',
        codPacCAT: '#NAOEXIBIR#',
        codPacOcorrenciaCAT: '#NAOEXIBIR#',
        executorAvaliacao: '#NAOEXIBIR#',
        executorMedicaoData: '#NAOEXIBIR#',
        codPacPressao: '#NAOEXIBIR#',
        cODHOSPDATA: '#NAOEXIBIR#',
        tecnicoResponsavel: 'Profissional',
        frequenciaCardiacaBpm: 'Frequência Cardíaca (BPM)',
        frequenciaRespiratoriaICP: 'Frequência Respiratória (ICP)',
        glicemiaCapilarEmJejumMlDl: '(Glicemia Capilar em Jejum (ml/dl)',
        posPrandialMlDl: 'Glicemia pós-prandial (ml/dl)',
        glicose: 'Glicose',
        pADiastolicaRef80: 'Pressão Diastólica (ref: 80)',
        pASistolicaRef120: 'Pressão Sistólica (ref: 120)',
        saturacaoOxigenioSO: 'Saturação de Oxigênio',
        temperaturaTax: 'Temperatura'
      };
      if (mapR.hasOwnProperty(field)) {
        return mapR[field];
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getRotulo', error.message);
    }
    return '';
  }

}
