import { VitalSignListComponent } from './pwa/view/vital-sign-list/vital-sign-list.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmergencyDataComponent } from './pwa/view/emergency-data/emergency-data.component';
import { CardPatientComponent } from './pwa/view/card-patient/card-patient.component';
import { LongitudinalHistoryComponent } from './pwa/view/longitudinal-history/longitudinal-history.component';
import { EvolutionComponent } from './pwa/view/evolution/evolution.component';
import { VitalSignComponent } from './pwa/view/vital-sign/vital-sign.component';
import { CardMedicationComponent } from './pwa/view/card-medication/card-medication.component';
import { EvolutionTimelineComponent } from './pwa/view/evolution-timeline/evolution-timeline.component';
import { GenericActivityComponent } from './pwa/view/generic-activity/generic-activity.component';
import { AuthChildGuardService } from '@medlogic/shared/gecore';
import { LocalLoginViewComponent } from './module/core/view/local-login-view/local-login-view.component';
import { PageNotFoundViewComponent } from '@medlogic/shared/geform';
import { EvolutionListComponent } from './pwa/view/evolution-list/evolution-list.component';
import { PatientCarePlanComponent } from './pwa/view/patient-care-plan/patient-care-plan.component';
import { PatientCarePlanActivityDetailComponent } from './pwa/view/patient-care-plan-activity-detail/patient-care-plan-activity-detail.component';
import { CarePlanReportComponent } from './pwa/view/care-plan-report/care-plan-report.component';

const appRoutes: Routes = [
  {
    path: 'pwa',
    canActivateChild: [AuthChildGuardService],
    children: [
      // History
      { path: 'login', component: LocalLoginViewComponent },
      { path: 'history/:key/:patientCod/:patientName/:date', component: LongitudinalHistoryComponent }, // Histórico Longitudinal
      // Mapa de Cuidado
      { path: 'medication/:cadCheckMedicamentosNo/:cadStockNo', component: CardMedicationComponent },
      { path: 'carecard/:modeloConfigNo', component: CardPatientComponent },
      { path: 'carecard', component: CardPatientComponent },
      // { path: 'caretable', component: CareMapComponent },
      // { path: 'careprescription', component: CardPrescriptionComponent },
      // { path: 'caretimeline', component: PatientTimelineComponent },
      { path: 'vitalsign/list', component: VitalSignListComponent },
      { path: 'vitalsign/:key', component: VitalSignComponent },
      { path: 'evolution/list', component: EvolutionListComponent },
      { path: 'evolution/:key', component: EvolutionComponent },
      { path: 'activity/:key', component: GenericActivityComponent },
      { path: 'evolution-timeline/:key', component: EvolutionTimelineComponent },
      { path: 'emergencydata/:key', component: EmergencyDataComponent },
      { path: 'careplan/create', component: PatientCarePlanActivityDetailComponent },
      { path: 'careplan/:key', component: PatientCarePlanComponent },
      { path: 'careplan/detail/:key', component: PatientCarePlanActivityDetailComponent },
      { path: 'careReport/:key', component: CarePlanReportComponent },
      // Redirect
      { path: '', redirectTo: 'login', pathMatch: 'prefix' },
      { path: '**', component: PageNotFoundViewComponent },
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(appRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppPwaRoutingModule { }
