import { EnActionType, EnActivityType, IActionEmitter, IActivityTypeCodeableConcept, AppLogService } from '@medlogic/shared/shared-interfaces';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'ml-add-activity-dialog',
  templateUrl: './add-activity-dialog.component.html',
  styleUrls: ['./add-activity-dialog.component.css']
})
export class AddActivityDialogComponent implements OnInit {

  activityType: any;
  @Input() activityTypes: IActivityTypeCodeableConcept[];

  @Output() actionClick = new EventEmitter<IActionEmitter>();

  ENACTIONTYPE = EnActionType;

  constructor(
    private log: AppLogService,
    private dialogRef: MatDialogRef<AddActivityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { activityTypes: IActivityTypeCodeableConcept[] }
  ) {
    this.activityTypes = data.activityTypes;
  }

  ngOnInit() {
    this.dialogRef.updateSize("40%", "40%");

  }

  onActionClick(actionType: EnActionType, selectedActivityType?: EnActivityType): void {
    try {
      this.dialogRef.close({ actionType, selectedActivityType });
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'onActionClick', error.message);
    }
  }

  onActivityTypeChange(selectedActivityType: {name: string, type: string}): void {
    try {
      this.dialogRef.close({ actionType: EnActionType.confirm, selectedActivityType });
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'onActivityTypeChange', error.message);
    }
  }


}
