import { clearPatients } from '@medlogic/medlogic/medlogic-state';
import { IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import {
  isPatientLoading, clearSelectedPatient,
  selectedPatient, setPatient, loadPatientsWithMedications, isMedicationLoading, selectPatientAllActive, clearMedications
} from '@medlogic/medlogic/medlogic-state';
import { ActivatedRoute } from '@angular/router';
import { IMonth } from '../../../assist/interface/imonth';
import { IMedication, ConfigJsonService, AppLogService } from '@medlogic/shared/shared-interfaces';
import { IPatient } from '@medlogic/shared/shared-interfaces';
import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { IResponsibleFamily } from '../../interface/iresponsible-family';
import { CardPatientProvider } from '../../provider/card-patient.provider';
import { NavigationPwaService } from '../../service/navigation-pwa.service';
import { LocalMsgPtBR } from '../../../module/core/service/local-msg-ptBR.service';
import {
  HomeView,
  CustomerConfigService,
} from '@medlogic/shared/gecore';
import { Store } from '@ngrx/store';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { ModelComponent } from '@medlogic/shared/shared-data-access';
import { ConfigPwaMedLogicService } from '../../../pwa/service/config-pwa-medlogic.custom.service';
import { Observable, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ml-card-patient',
  templateUrl: './card-patient.component.html',
  styleUrls: ['./card-patient.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardPatientComponent extends HomeView implements OnInit {

  // patients: IPatient[] = new Array<IPatient>(); // listagem de todos os pacients
  patients$: Observable<IPatient[]> = this.store.select(selectPatientAllActive);
  selectedPatient$: Observable<IPatient> = this.store.select(selectedPatient);
  isLoading$: Observable<boolean> = this.store.select(isPatientLoading);
  isLoadingMedications$: Observable<boolean> = this.store.select(isMedicationLoading);

  lstConfig: any[] = [];
  medication = {} as IMedication; // armazena a medicação selecionada
  months: IMonth[];
  selectedMonth: any = 0;
  weekday: string;
  today: string;
  keyword = '';
  currentTime$ = new BehaviorSubject<Date>(new Date());
  isAction = false;
  cadPacienteNo = -1;
  cadVitalSignsNo = -1;
  cadIntervecoesMedicamentosNo = -1;
  cadIntervecoesHorariosNo = -1;
  cadCheckMedicamentosNo = -1;
  cadResponsavelFamiliaNo = -1;
  cadCheckVitalSignsNo: number;
  cadEvolutionNo: number;
  cadEstoqueMateriaisNo: number;
  cadCareRoutineNo: number;
  cadCheckinCareRoutineNo: number;
  resFamily: IResponsibleFamily[] = new Array<IResponsibleFamily>();
  showTime = true;
  showMiniImg = false; // Mostra as fotos em tamanho menor. OBS: Está desativado, pois, com a nova disposição as imagens já são pequenas.

  // FIXME: tornar dinâmico, conforme configurações do cliente.
  showVitalSigns = false;
  showPatientReport = false;
  showMedicationCheckin = false;
  showDailyEvaluation = true;
  showCarePlan = true;
  showCustomModules = false;
  showCarePlanReport = true;

  @ViewChild('modalDialog', { static: true }) modalDialog;

  public get currentMonth(): IMonth {
    try {
      if (this.months !== undefined) {
        return this.months.find(f => f.value === this.selectedMonth);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'currentMonth', error.message);
    }
    return null;
  }

  constructor(
    log: AppLogService,
    glb: GlobalService,
    cnf: ConfigPwaMedLogicService,
    route: ActivatedRoute,
    modelComponent: ModelComponent,
    customCnf: CustomerConfigService,
    msg: LocalMsgPtBR,
    public pv: CardPatientProvider,
    private cnfJson: ConfigJsonService,
    protected nav: NavigationPwaService,
    private store: Store<IAppMedlogicState>,
  ) {
    super(log, glb, cnf, route, nav, modelComponent, customCnf, msg);
    // TODO: O cenário ideal é criar um websocket de forma que mudanças na prescrição notifiquem os cuidadores e forcem a atualização das medicações.
    // Atualizará uma única vez, após o login, ou em acionamento do botão de atualização.
    this.store.dispatch(clearMedications());
    this.store.dispatch(loadPatientsWithMedications());
  }

  ngOnInit(): void {
    try {
      this.nav.addToHistory(this.route.snapshot.url.map(m => m.path), '[carecard] CardPatient');
      // Evitar o recarregamento desnecessário dos dados. No entanto, se o cliente operar com tables em simultaneidade, o recarregamento faz sentido.
      // Se há problema no carregamento, o fato de não recarregará fará com que o problema persista
      // this.subs.sink = this.store.pipe(select(selectPatientAll), first(), tap((patients) => {
      //   if (this.cnfJson.alwaysReload || !patients || patients?.length <= 0) {
      // FIXME: apesar de ser uma ideia interessante, esse duplo carregamento está zerando a lista de pacientes após alguns segundos
      // FIXME: ATENÇÃO: O USO DESSE MÉTODO SOMENTE SERÁ POSSÍVEL QUANDO O CACHE DO REDIS ESTIVER ATIVO!!!
      // FUNCIONA AGORA COM A API ANTIGA. SE PUBLICAR A NOVA PARARÁ DE FUNCIONAR EXCETO SE O CACHE ESTIVER ATIVO
      this.refresh();
      // this.store.dispatch(loadMedicationsBySelectedPatient({ numOfDaysToCheckOcurrency: 10 }));
      // this.subs.sink = this.store.select(state => state?.tenant?.selectedTenant)
      //   .pipe(
      //     take(1),
      //     tap((tenant: ITenant) => {
      //       this.cadPacienteNo = tenant?.cadPacienteNo;
      //       this.cadResponsavelFamiliaNo = tenant?.cadResponsavelFamiliaNo;
      //       this.cadVitalSignsNo = tenant?.cadVitalSignsNo;
      //       this.cadIntervecoesMedicamentosNo = tenant?.cadIntervecoesMedicamentosNo;
      //       this.cadIntervecoesHorariosNo = tenant?.cadIntervecoesHorariosNo;
      //       this.cadCheckMedicamentosNo = tenant?.cadCheckMedicamentosNo;
      //       this.cadCheckVitalSignsNo = tenant?.cadCheckVitalSignsNo;
      //       this.cadEvolutionNo = tenant?.cadEvolutionNo;
      //       this.cadEstoqueMateriaisNo = tenant?.cadEstoqueMateriaisNo;
      //       this.cadCareRoutineNo = tenant?.cadCareRoutineNo;
      //       this.cadCheckinCareRoutineNo = tenant?.cadCheckinCareRoutineNo;
      //       this.refresh(
      //         this.cadPacienteNo,
      //         this.cadEvolutionNo,
      //         this.cadIntervecoesMedicamentosNo,
      //         this.cadCheckMedicamentosNo,
      //         this.cadIntervecoesHorariosNo,
      //         this.cadVitalSignsNo,
      //         this.numOfDaysToCheckOcurrency,
      //         this.pv.isOffLineMode
      //       );
      //     }),
      //     error()
      //   ).subscribe();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  refreshClick($event: any): void {
    try {
      this.store.dispatch(clearPatients());
      this.store.dispatch(clearMedications());
      this.store.dispatch(loadPatientsWithMedications());
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'refreshClick', error.message);
    }
  }

  protected setCadParams(): void {
    try {
      // this.cadParams = [
      //   { idName: 'cadPacienteNo', label: 'Cad_Paciente' } as ICadParam,
      //   { idName: 'cadResponsavelFamiliaNo', label: 'Cad_Responsavel_Familia' } as ICadParam,
      //   { idName: 'cadIntervecoesMedicamentosNo', label: 'Cad_Intervecoes_Medicamentos' } as ICadParam,
      //   { idName: 'cadIntervecoesHorariosNo', label: 'Cad_Intervencoes_Horario' } as ICadParam,
      //   { idName: 'cadCheckVitalSignsNo', label: 'Cad_Check_SinaisVitais' } as ICadParam,
      //   { idName: 'cadCheckMedicamentosNo', label: 'Cad_Check_Medicamentos' } as ICadParam,
      //   { idName: 'cadEstoqueMateriaisNo', label: 'Cad_Estoque_Materiais' } as ICadParam,
      //   { idName: 'cadCareRoutineNo', label: 'Cad_Rotinas_Cuidado_Hóspede' } as ICadParam,
      //   { idName: 'cadCheckinCareRoutineNo', label: 'Cad_Checkin_Rotina_Cuidado' } as ICadParam,
      //   { idName: 'cadVitalSignsNo', label: 'Cad_SinaisVitais_SinglePage' } as ICadParam,
      //   { idName: 'cadEvolutionNo', label: 'Cad_Evolucao_SinglePage' } as ICadParam
      // ];
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'setCadParams', error.message);
    }
  }

  protected setCurrentRoute(): void {
    try {
      this.currentRoute = this.nav.getRouteCardPatient(this.cnf.modeloConfigNo);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'setCurrentRoute', error.message);
    }
  }

  /* Carrega os campos na tela. Carregará, além de todos os pacientes,
  * suas respectivas medicações. O card de medication receberá, portanto,
  * esse dado já pronto.
  */
  protected refresh(
    // cadPacienteNo: number,
    // cadEvolutionNo: number,
    // cadIntervecoesMedicamentosNo: number,
    // cadCheckMedicamentosNo: number,
    // cadIntervecoesHorariosNo: number,
    // cadVitalSignsNo: number,
    // numOfDaysToCheckOcurrency: number,
    // isOffLineMode: boolean
  ): void {
    try {
      // const dtEnd = this.glb.addDays(new Date(), 1); // Necessário para incluir a data do dia
      // const dtStart = this.glb.addDays(dtEnd, -this.numOfDaysToCheckOcurrency);
      // FIXME: Atenção: carregará a lista de pacientes duas vezes, mas na primeira, sem as medicações
      // é uma tentativa de acelerar o carregamento inicial e, então, carregar a medicação na sequencia
      // this.store.dispatch(loadPatients());
      const today = new Date();
      this.weekday = `${this.glb.DiaDaSemana(today.getDay())}`;
      this.today = `${today.getDate()} de ${this.glb.NomeDoMes(today)} de ${today.getFullYear()}`;
      this.setTimer();
      // if (!(this.pv.patients && this.pv.patients.length > 0)) {
      // Para que o status seja recalculado, será necessário recarregar os dados a cada refresh.
      // this.evolutionSrv.clearCache();
      // this.subs.sink = this.getPatientsFromService(cadPacienteNo, cadEvolutionNo, dtStart, dtEnd, today).subscribe();
      // } else {
      // this.isLoading = false;
      // }
      // this.subs.sink = this.getPatientsFromService(cadPacienteNo, cadEvolutionNo, dtStart, dtEnd, today).subscribe();
      // const patients$ = isOffLineMode ? this.patientSrv.getPatientsWithMedications(
      //   cadPacienteNo,
      //   cadEvolutionNo,
      //   cadIntervecoesMedicamentosNo,
      //   cadCheckMedicamentosNo,
      //   cadIntervecoesHorariosNo,
      //   cadVitalSignsNo,
      //   dtStart,
      //   dtEnd,
      //   numOfDaysToCheckOcurrency) : this.patientSrv.getAll(cadPacienteNo).pipe(toArray());
      // this.subs.sink = patients$
      //   .pipe(
      //     tap(patients => this.pv.patients = patients),
      //     tap(() => this.isLoading = false)
      //   )
      //   .subscribe();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'refresh', error.message);
    }
  }

  /* Resgata as entradas a partir do Serviço.
   */
  // getPatientsFromService(
  //   cadPacienteNo: number,
  //   cadEvolutionNo: number,
  //   dtStart: Date,
  //   dtEnd: Date,
  //   referenceDate: Date = null): Observable<IPatient> {
  //   try {
  //     // Resgata os registros modificados e os converte ao padrão adequado
  //     // const changedItems = this.getChangedItems(entryCadastroNo);
  //     const dtEd = this.glb.addDays(new Date(), 1); // Necessário para incluir a data do dia
  //     const dtSt = this.glb.addDays(dtEd, -this.numOfDaysToCheckOcurrency);
  //     const getMedications = () => mergeMap((patient: IPatient) =>
  //       this.intervecoesMedicamentosSrv.getMedicationsForPatient(
  //         this.cadIntervecoesMedicamentosNo,
  //         this.cadCheckMedicamentosNo,
  //         this.cadIntervecoesHorariosNo,
  //         patient,
  //         dtStart,
  //         dtEnd,
  //         referenceDate)
  //         .pipe(
  //           mergeMap((medications: IMedication[]) => {
  //             patient.medications = medications;
  //             return of(patient);
  //           })
  //         )
  //     );
  //     const getOccurrences = () => mergeMap((patient: IPatient) => {
  //       const ocurrences$ = forkJoin([
  //         this.evolutionSrv.getHasOccurrence(cadEvolutionNo, patient.codigo, dtSt, dtEd),
  //         this.vitalSignSrv.getHasOccurrence(this.cadVitalSignsNo, patient.codigo, dtSt, dtEd)
  //       ]);
  //       return ocurrences$
  //         .pipe(
  //           map<[any, any], IPatient>(([evOccurence, vsOccurrence]) => {
  //             try {
  //               patient.calcHasEvolutionOccurrence = evOccurence ? evOccurence?.calcHasOccurrence : false;
  //               patient.calcHasOccurrence = vsOccurrence ?
  //                 patient?.calcHasEvolutionOccurrence || vsOccurrence?.calcHasOccurrence :
  //                 patient?.calcHasEvolutionOccurrence;
  //               patient.calcHasVitalSignOccurrence = vsOccurrence ? vsOccurrence?.calcHasOccurrence : false;
  //             } catch (error) {
  //               this.log.Registrar(this.constructor.name, 'getPatientsFromService.getHasOccurrence', error.message);
  //             }
  //             return patient;
  //           })
  //         );
  //     });
  //     const error = () => catchError((err, obs) => {
  //       console.log(err.message);
  //       this.matDialog.openDialogAlert('Erro ao buscar pacientes!');
  //       this.isLoading = false;
  //       return of(err);
  //     });
  //     const sortOnOcurrencyAndNames = () => tap((patients: IPatient[]) => {
  //       return patients.sort((a, b) => {
  //         if (a.calcHasOccurrence === b.calcHasOccurrence) {
  //           return a.nomeHospede < b.nomeHospede ? -1 : 1;
  //         } else {
  //           return a.calcHasOccurrence ? -1 : 1;
  //         }
  //       });
  //     });
  //     const complete = () => tap((patientWithMedications: IPatient[]) => {
  //       this.pv.patients = patientWithMedications;
  //       this.isLoading = false;
  //     });
  //     // Pipe
  //     return this.patientSrv.getAll(cadPacienteNo, null, null)
  //       .pipe(
  //         filter(f => !!f.situacaoPaciente),
  //         getMedications(),
  //         getOccurrences(),
  //         toArray(),
  //         sortOnOcurrencyAndNames(),
  //         complete(),
  //         error()
  //       );
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'getEntriesFromCacheOrService', error.message);
  //     this.isLoading = false;
  //   }
  //   return of(null);
  // }

  showDialog(medication: IMedication, patient: IPatient): void {
    try {
      if (!medication.took) {
        medication.medicationDate = new Date();
        this.modalDialog.displayDialog = !this.modalDialog.displayDialog;
        this.medication = medication;
        // this.selectedPatient = patient;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'showDialog', error.message);
    }
  }

  /* Ativa o relógio */
  protected setTimer(): void {
    try {
      setInterval(() => {
        this.currentTime$.next(new Date());
      }, 1000);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'setTimer', error.message);
    }
  }

  /* Clique no input de pesquisa.
  * Torna invisível os elementos desnecessários.
  */
  onSearchClick(event: any): void {
    try {
      // this.toogleVisualElements(false);
      this.gotoPatientsList();
      // this.cnf.showMenu = false;
      // this.showMiniImg = true;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onSearchClick', error.message);
    }
  }

  /* Retorna para a página de lista de pacientes. */
  protected gotoPatientsList(): void {
    try {
      // this.selectedPatient = null;
      this.store.dispatch(clearSelectedPatient());
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'gotoPatientsList', error.message);
    }
  }

  /* Limpará a busca e restaurará o tamanho grande. */
  onClearSearch(): void {
    try {
      this.keyword = '';
      // this.showMiniImg = false;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onClearSearch', error.message);
    }
  }

  /* Perda de foco no input de pesquisa.
  * Torna invisível os elementos desnecessários.
  */
  onBlurClick($event: any): void {
    try {
      // this.toogleVisualElements(true);
      if (this.glb.isNullOrEmpty(this.keyword)) { // Se a caixa de pesquisa estiver vazia, ampliará os ícones novamente.
        // this.showMiniImg = false;
      } else {
        this.gotoPatientsList();
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onBlurClick', error.message);
    }
  }

  /* Irá esconder/exibir os elementos visuais acessórios para ampliar o espaço para o resultado de busca.
  * Mas caso esteja restabelecendo, terá um pequeno delay para que não impeça um click num botão devido a mudar o botão de posição.
  */
  protected toogleVisualElements(isVisible: boolean): void {
    try {
      if (isVisible) { // Se estiver reexibindo os elementos, o faz com pequeno atraso.
        setTimeout(() => {
          // this.showTime = isVisible;
          this.cnf.showMenu = true;
        }, 500);
      } else {
        // this.showTime = isVisible;
        this.cnf.showMenu = true;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'toogleVisualElements', error.message);
    }
  }

  /* Paciente selecionado. */
  onPatientClick(patient: IPatient): void {
    try {
      // this.selectedPatient = patient;
      if (patient) {
        this.store.dispatch(setPatient({ selectedId: +patient.codigo }));
        this.isAction = true;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onPatientClick', error.message);
    }
  }

  /* Click no voltar das ações para a lista de pacientes. */
  onActionBackClick(): void {
    try {
      this.isAction = false;
      // this.selectedPatient = null;
      this.store.dispatch(clearSelectedPatient());
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onActionBackClick', error.message);
    }
  }


}
