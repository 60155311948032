import { FhirFieldsService, selectedActivityName, selectedActivityType, selectPractitionerAllActive, upsertProgress, upsertUnscheduledProgress } from '@medlogic/fhir';
import { selectedActivity } from '@medlogic/fhir';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { IPractitioner, IActivity, IProgress, EnCrud, UnsubscribeOnDestroyAdapter, EnActivityType, IField, AppLogService } from '@medlogic/shared/shared-interfaces';
import { Component, OnInit } from '@angular/core';
import { NavigationPwaService } from '../../service/navigation-pwa.service';
import { MatTableDataSource } from '@angular/material/table';
import { IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { select, Store } from '@ngrx/store';
import { selectSelectedLogin } from '@medlogic/medlogic-pwa/medlogic-pwa-cuidado-full';
import { AppMedlogicPwaCuidadoState } from '@medlogic/medlogic-pwa/medlogic-pwa-cuidado-full';

// esse import está correto???





@Component({
  selector: 'ml-patient-care-plan-activity-detail',
  templateUrl: './patient-care-plan-activity-detail.component.html',
  styleUrls: ['./patient-care-plan-activity-detail.component.css']
})
export class PatientCarePlanActivityDetailComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  activity$: Observable<IActivity> = this.store.select(selectedActivity);
  practitioners$: Observable<MatTableDataSource<IPractitioner>> = this.store.select(selectPractitionerAllActive).pipe(map(m => new MatTableDataSource<IPractitioner>(m)));
  selectedPractitioner$ = this.store.pipe(select(selectSelectedLogin));

  crud$: Observable<EnCrud> = this.store.pipe(select(selectedActivity), map(m => m ? EnCrud.update : EnCrud.create));
  activityType$: Observable<EnActivityType> = this.store.pipe(select(selectedActivityType));
  activityName$: Observable<string> = this.store.pipe(select(selectedActivityName));

  fields$ = new Observable<IField[] | null>();

  constructor(
    private log: AppLogService,
    private nav: NavigationPwaService,
    private store: Store<IAppMedlogicState | AppMedlogicPwaCuidadoState>,
    private fieldsSrv: FhirFieldsService
  ) {
    super();
  }

  ngOnInit() {
    // // get url parts from activated route
    // const urlParts = this.router.snapshot.url.map(m => m.path);
    // // get mode from url parts
    // this.mode = urlParts[1] as EnCrud;
    this.loadCarePlanFields();
  }

  loadCarePlanFields() : void{

  this.fields$ = this.fieldsSrv.getAll<IField[]>()
  .pipe(
    tap( fields => {
      return console.log(fields)
    }),
    catchError((err, obs) => {
      console.log(err);
      return obs;
    })
  )

  }

  onBackClick($event: any): void {
    try {
      this.nav.back();
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'onBackClick', error.message);
    }
  }

  onUpdateValues(values: any): void {
    try {
      console.info("update values: ", values);
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'onUpdateValues', error.message);
    }
  }
  onConfirm(event: { progress: IProgress, activityType: EnActivityType }): void {
    try {
      const { progress, activityType } = event;
      if (activityType === EnActivityType.Scheduled) {
        console.warn("dispatched upsert progress: ", progress);
        this.store.dispatch(upsertProgress({ progress }));
      } else {
        console.warn("dispatched unscheduled progress: ", { progress, activityType })
        this.store.dispatch(upsertUnscheduledProgress({ progress, activityType }))
      }
      this.nav.back();
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'onConfirm', error.message);
    }
  }
}
