import { IActivity, EnActivityStatus, IActivityIcon, ILogin, UnsubscribeOnDestroyAdapter, AppLogService } from '@medlogic/shared/shared-interfaces';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppMedlogicPwaCuidadoState, selectSelectedLogin } from '@medlogic/medlogic-pwa/medlogic-pwa-cuidado-full';
import { Observable, take } from 'rxjs';

@Component({
  selector: 'ml-ui-care-plan-activity',
  templateUrl: './ui-care-plan-activity.component.html',
  styleUrls: ['./ui-care-plan-activity.component.css']
})
export class UiCarePlanActivityComponent extends UnsubscribeOnDestroyAdapter implements OnInit {


  @Output() activityClick = new EventEmitter<IActivity>();

  @Input() activity: IActivity;

  @Input() activityIcons: IActivityIcon[] | null = [];

  icons : string[] = [];
  currentDate: string | Date;
  selectedPractitioner: ILogin;
  $selectedPractitioner: Observable<ILogin>;

  ENACTIVITYSTATUS = EnActivityStatus;

  constructor(
    private log: AppLogService,
    private store: Store<AppMedlogicPwaCuidadoState>
  ) {
    super();
  }

  ngOnInit() {
    // this.loadIcons(); TODO: When confirmed that there will be no icons on activities, remove all loadIcons references
    try {
      const utcDate = !this.activity?.currentActivityDetail?.Scheduled?.ScheduledPeriod?.start ?
        new Date(new Date(this.activity.currentProgress.time).setHours(new Date(this.activity.currentProgress.time).getHours())) : new Date(new Date(this.activity?.currentActivityDetail?.Scheduled?.ScheduledPeriod?.start).setHours(new Date(this.activity?.currentActivityDetail?.Scheduled?.ScheduledPeriod?.start).getHours() + 3));
      // console.log(this.activity?.currentActivityDetail?.Scheduled?.ScheduledPeriod?.start);
      // console.log("utc: ", utcDate.toISOString()).toString());
      // console.log("loc: ", utcDate.toLocaleTimeString();
      this.currentDate = utcDate ? utcDate.toLocaleTimeString().slice(0, 5) : new Date(this.activity?.currentProgress?.time).toLocaleTimeString().slice(0,5);
    } catch (error: any) {
       this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
    // return null;
    // TODO: Discover why new Date() interprets as UTC a timezone time

    this.subs.sink = this.store.pipe(select(selectSelectedLogin), take(1)).subscribe(usuario => {
      this.selectedPractitioner = usuario;
    });
  }



  onActivityClick(activity: IActivity): void {
    try {
      console.log("atividade clicada: ");
      console.warn(activity);
      this.activityClick.emit(activity);
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'onActivityClick', error.message);
    }
  }

  translateStatus(status: string) {
    try {
      switch (status) {
        case "done":
          return "Atividade completa";
        case "todo":
          return "Atividade programada";
        case "warn":
          return "Período de tolerância";
        case "pending":
          return "Atividade atrasada";
        case "late":
          return "Atividade completa fora do período de tolerância";
        case "extra":
          return "Atividade não programada"
        default:
          break;
      }
    } catch (error: any) {
       this.log.Registrar(this.constructor.name, 'translateStatus', error.message);
    }
    // return null;
  }
}
