import { EnActivityMode, GlobalService, IActivity, EnActivityStatus, IShift, EnShift } from '@medlogic/shared/shared-interfaces';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { tap } from 'rxjs';
import { IActivityState } from './activity-state';

import * as fromActivity from './activity.reducer';

const glb = new GlobalService();

export const selectActivityState = createFeatureSelector<IActivityState>(fromActivity.activityFeatureKey); //TODO: antes estava activitiesFeatureKey (Validar)
export const selectActivityAll = createSelector(selectActivityState, fromActivity.selectAll);

export const selectActivitiesByShift = createSelector(
  selectActivityAll,
  activities => Object.keys(
    activities?.reduce((acc, activity) => {
      const activityShiftTime = activity?.currentActivityDetail?.Scheduled?.ScheduledPeriod?.start ? // ou tem scheduled, ou pega o time do progress de uma atividade unscheduled
        new Date(activity?.currentActivityDetail?.Scheduled?.ScheduledPeriod?.start).setHours(new Date(activity?.currentActivityDetail?.Scheduled?.ScheduledPeriod?.start).getHours() + 3) :
        activity?.currentProgress?.time;
      // reduce activities by shift
      const shiftName = glb.getShift(glb.dateTohhmmss(activityShiftTime, false));
      if (!acc[shiftName]) {
        acc[shiftName] = [];
      }
      acc[shiftName].push(activity);
      return acc;
    },
      {} as { [key: string]: IActivity[] }
    )
  ).reduce((acc, shiftName) => {
    const activitiesOfShiftOrderedByTime = activities
      .filter(activity =>{
        const activityShiftTime = activity?.currentActivityDetail?.Scheduled?.ScheduledPeriod?.start ?
        new Date(activity?.currentActivityDetail?.Scheduled?.ScheduledPeriod?.start).setHours(new Date(activity?.currentActivityDetail?.Scheduled?.ScheduledPeriod?.start).getHours() + 3) :
        activity?.currentProgress?.time;
        return glb.getShift(glb.dateTohhmmss(activityShiftTime, false)) === shiftName;
      })
      .sort((a: any, b: any) => {
        const aShiftDate = new Date();
        aShiftDate.setHours(new Date(a?.currentActivityDetail?.Scheduled?.ScheduledPeriod?.start).getHours() + 3);
        aShiftDate.setMinutes(new Date(a?.currentActivityDetail?.Scheduled?.ScheduledPeriod?.start).getMinutes());

        const aShiftTime = a?.currentActivityDetail?.Scheduled?.ScheduledPeriod?.start ?
        aShiftDate : new Date(a?.currentProgress?.time as any);

        const bShiftDate = new Date();
        bShiftDate.setHours(new Date(b?.currentActivityDetail?.Scheduled?.ScheduledPeriod?.start).getHours() + 3);
        bShiftDate.setMinutes(new Date(b?.currentActivityDetail?.Scheduled?.ScheduledPeriod?.start).getMinutes());

        const bShiftTime = b?.currentActivityDetail?.Scheduled?.ScheduledPeriod?.start ?
        bShiftDate : new Date(b?.currentProgress?.time as any);

        const aHours = aShiftTime.getHours();
        const bHours = bShiftTime.getHours();
        const aMinutes = aShiftTime.getMinutes();
        const bMinutes = bShiftTime.getMinutes();
        if (aHours === bHours) {
          return aMinutes - bMinutes;
        }
        return aHours - bHours;
      });

    acc.push({
      shiftName,
      activities: activitiesOfShiftOrderedByTime
    });

    return acc;

  },
    [] as IShift[]
  ).sort((a, b) => {
    // define the desired order of shifts
    const shiftOrder = ['Manhã', 'Tarde', 'Noite'];
    const aIndex = shiftOrder?.indexOf(a.shiftName);
    const bIndex = shiftOrder?.indexOf(b.shiftName);
    return aIndex - bIndex;
  })
);

export const selectActivitiesPlannedByShift = createSelector(
  selectActivitiesByShift,
  activities => activities.map(activity => ({ ...activity, activities: activity.activities.filter(a => a.status !== EnActivityStatus.extra) }))
);

export const selectActivitiesExtraByShift = createSelector(
  selectActivitiesByShift,
  activities => activities.map(activity => ({ ...activity, activities: activity.activities.filter(a => a.status === EnActivityStatus.extra) }))
);

export const selectedActivity = createSelector(selectActivityState, state => {
  return state?.selectedId ? state?.entities[state?.selectedId] : null;
});

export const selectedActivityMode = createSelector(selectActivityState, state => {
  return state?.selectedActivityMode;
});

export const selectedActivityType = createSelector(selectActivityState, state => {
  return state?.selectedActivityType;
});

export const selectedActivityName = createSelector(selectActivityState, state => {
  return state?.selectedActivityName;
});

export const selectedActivityModeTitle = createSelector(selectActivityState, state => {
  switch (state?.selectedActivityMode) {
    case EnActivityMode.Extra:
      return 'Atividades Não Programadas';
    default:
    case EnActivityMode.CarePlan:
      return 'Atividades Programadas';
  }
});

export const selectedActivityIdentifier = createSelector(selectActivityState, state => {
  return (state?.selectedId || 0) > 0 ? state?.selectedId : null;
});

export const selectedShift = createSelector(selectActivityState, state => {
  return (state?.selectedShift || EnShift.all);
});

export const selectActivitityTypes = createSelector(selectActivityState, state => {
  return state.activityTypes;
});

export const isActivityLoading = createSelector(
  selectActivityState,
  state => state.isLoading
);
