
import { refCount, publishReplay, map, filter } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { IEvolucaoEnfermagem } from '@medlogic/shared/shared-interfaces';
import { Observable, of } from 'rxjs';
import { EMPTY } from 'rxjs';
import { GlobalService, LogService, IForm } from '@medlogic/shared/shared-interfaces';
import { CadastroService } from '@medlogic/shared/shared-data-access';

export abstract class EvolucaoEnfermagemService {

  // tslint:disable-next-line: max-line-length
  protected lstVariaveis = 'V_387,V_391,V_1608,V_3328,V_3329,V_28051,V_101175,V_101305,V_101570,V_101573,V_101575,V_101578,V_101579,V_101580,V_101581,V_104082,V_104088,V_105415,V_105416,V_105417,V_105988,V_108792,V_108793,V_108794,V_108795,V_108796,V_108797,V_108798,V_108799,V_108800,V_108801,V_108803,V_108804,V_108805,V_108806,V_108807,V_108811,V_108812,V_108814,V_108822,V_108823,V_108825,V_108826,V_108827,V_108828,V_108829,V_108830,V_108831,V_108832,V_108833,V_108834,V_108835,V_108836,V_108837,V_108838,V_108839,V_108840,V_108841,V_108842,V_108843,V_108844,V_108845,V_108876,V_108877,V_108878,V_108879,V_108880,V_108881,V_108882,V_108883,V_108884,V_108885,V_108886,V_108887,V_108888,V_108889,V_108890,V_108891,V_108892,V_108893,V_108894,V_108895,V_108896,V_108897,V_108898,V_108899,V_108900,V_108901,V_108902,V_108906,V_108907,V_108908,V_108909,V_108910,V_108911,V_108912,V_108913,V_108915,V_108916,V_108917,V_108918,V_108919,V_108920,V_108921,V_108922,V_108923,V_108924,V_108925,V_108926,V_108927,V_108928,V_108929,V_108930,V_108931,V_108932,V_108933,V_108934,V_108935,V_108936,V_108937,V_108938,V_108939,V_108940,V_108941,V_108942,V_108943,V_108944,V_108945,V_108946,V_108947,V_108948,V_108949,V_108950,V_108951,V_108952,V_108953,V_108954,V_108955,V_108956,V_108957,V_108958,V_108959,V_108960,V_108961,V_108962,V_108975,V_108976,V_108977,V_108978,V_108979,V_108980,V_108986,V_108987,V_108988,V_108989,V_108990,V_108991,V_108992,V_108993,V_108994,V_108995,V_108998,V_108999,V_109000,V_109007,V_109013,V_109014,V_109015,V_109016,V_109017,V_109018,V_109020,V_109022,V_109024,V_109026,V_109028,V_109029,V_109030,V_109031,V_109032,V_109035,V_109039,V_109057,V_109058,V_109059,V_109064,V_109065,V_109170,V_109171,V_109175,V_109176,V_109177,V_109178,V_109179,V_109180,V_108231,V_108194,V_108360,V_109222,V_108897,V_108898,V_109300,V_109302,V_109306,V_109301';
  private variavelGrid = '';
  private lstVariaveisGrid = '';

  recurrences: Array<IEvolucaoEnfermagem> = new Array<IEvolucaoEnfermagem>();

  cadastroNo = 24567;
  currentDtInicial = new Date();
  currentDtFinal = new Date();
  cadastrosCache: Observable<any>;


  constructor(
    protected http: HttpClient,
    protected cadastroSrv: CadastroService,
    protected glb: GlobalService,
    protected log: LogService) {
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'constructor', error.message);
    }
  }

  // TODO: Atenção: o correto não é retornar todos os dados, de todas as datas e depois filtrar, mas enviar a data como parâmetro.
  getAll(ano: number, startDate?: Date, endDate?: Date): Observable<IEvolucaoEnfermagem> {
    try {
      this.cadastroNo = ano;
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      return this.getWithCache(this.cadastroNo, startDate, endDate).pipe(
        filter(f => {
          return this.glb.isBetweenIgnoreTime(f.dataEvolucao, startDate, endDate);
        }));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAll', error.message);
    }
    return null;
  }

  /* Método utilizado para popular uma lista com os itens ativos. */
  loadArray(ano: number): Observable<any> {
    try {
      const propLabel = 'titulo'; const propValue = 'codigo'; const propEnabled = 'habilitado';
      return this.cadastroSrv.loadArray(this.getAll(ano), propLabel, propValue, propEnabled);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'loadArray', error.message);
    }
  }

  /* Limpa o cache de forma que a próxima chamada buscará os dados do serviço novamente. */
  clearCache(): void {
    try {
      this.cadastrosCache = null;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'clearCache', error.message);
    }
  }

  protected getWithCache(cadastroNo: number, startDate: Date, endDate: Date): Observable<IEvolucaoEnfermagem> {
    if (
      (startDate.getTime() !== this.currentDtInicial.getTime())
      || (endDate.getTime() !== this.currentDtFinal.getTime())
      || (!this.cadastrosCache)
    ) {
      this.currentDtInicial = startDate;
      this.currentDtFinal = endDate;
      this.cadastrosCache = this.getFromCadastro(cadastroNo, startDate, endDate);
    } else {
      console.log('retorno do cache');
    }
    return this.cadastrosCache;
  }

  protected getFromCadastro(cadastroNo: number, startDate: Date, endDate: Date): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv
        .getCadastro(cadastroNo, this.lstVariaveis).pipe(
          map(m => this.toAttribute(m)),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadatro', error.message);
    }
    return null;
  }

  /* Converte o objeto que vem do serviço para IVitalSign. */
  protected toAttribute(c: any): IEvolucaoEnfermagem {
    try {
      return {
        ocorrenciaNo: c.OcorrenciaNo,
	nomeresidente: c.V_387,
	nascimento: this.glb.ddMMYYYYThhmmssToDate(c.V_391),
	nomeMae: c.V_1608,
	identificacao1: c.V_3328,
	identificacao2: c.V_3329,
	prontuario: c.V_28051,
	dataAniversario: c.V_101175,
	dataEvolucao: c.V_101305,
	admissao: c.V_101570,
	cPF: c.V_101573,
	idade: c.V_101575,
	endereco: c.V_101578,
	telefone: c.V_101579,
	celular: c.V_101580,
	email: c.V_101581,
	pai: c.V_104082,
	quarto: c.V_104088,
	codigo: c.V_105415,
	titulo: c.V_105416,
	habilitado: this.glb.getBoolean(c.V_105417),
	dataInternacao: this.glb.ddMMYYYYThhmmssToDate(c.V_105988),
	motivo: c.V_108793,
	local: c.V_108794,
	leitoHospitalar: c.V_108795,
	dataAlta: this.glb.ddMMYYYYThhmmssToDate(c.V_108796),
	diagnosticoFinal: c.V_108798,
	desfecho: c.V_108799,
	diasPermanenciaHospitalar: c.V_108800,
	profissionalQueIdentificoulesao: c.V_108801,
	ocorrenciaPRof: c.V_108803,
	andar: c.V_108804,
	dataIncidente: this.glb.ddMMYYYYThhmmssToDate(c.V_108805),
	dtIncidente: c.V_108806,
	localIncidente: c.V_108807,
	hematomas: this.glb.getBoolean(c.V_108811),
	queratose: this.glb.getBoolean(c.V_108812),
	outros: c.V_108814,
	internacaoRecente: this.glb.getBoolean(c.V_108822),
	terminalidade: this.glb.getBoolean(c.V_108823),
	dataAcionamento: this.glb.ddMMYYYYThhmmssToDate(c.V_108825),
	dtAcionamento: c.V_108826,
	horarioAcionamento: c.V_108827,
	medicoPlantonista: c.V_108828,
	medicoCAscata: c.V_108829,
	oCorrMedico: c.V_108830,
	cRM: c.V_108831,
	tipoCodigo: c.V_108832,
	queda: this.glb.getBoolean(c.V_108833),
	motivoAcionamento: c.V_108834,
	transferenciaHospitalar: this.glb.getBoolean(c.V_108835),
	houveContatomedicotelemedicinaComMedicoExterno: this.glb.getBoolean(c.V_108836),
	centrocustoidoso: c.V_108837,
	obito: this.glb.getBoolean(c.V_108838),
	horarioObito: c.V_108839,
	horariopreenchimentoDECLARACAODEOBITO: c.V_108840,
	dataAplicacao: this.glb.ddMMYYYYThhmmssToDate(c.V_108841),
	dtAplicacao: c.V_108842,
	enfermeiro: c.V_108843,
	enfCascata: c.V_108844,
	opcoesScore: c.V_108845,
	dtData: c.V_108876,
	necessidadeLavagem: this.glb.getBoolean(c.V_108877),
	dataLavagem: this.glb.ddMMYYYYThhmmssToDate(c.V_108879),
	dtLavagem: c.V_108880,
	observacoes: c.V_108881,
	usaMarcapasso: this.glb.getBoolean(c.V_108882),
	dataImplantacao: this.glb.ddMMYYYYThhmmssToDate(c.V_108883),
	dtImplantacao: c.V_108884,
	dataultimaRevisao: this.glb.ddMMYYYYThhmmssToDate(c.V_108885),
	dtUltimaRevisao: c.V_108886,
	dataproximaRevisao: this.glb.ddMMYYYYThhmmssToDate(c.V_108887),
	dtProximaRevisao: c.V_108888,
	encontraemPrecaucao: this.glb.getBoolean(c.V_108889),
	motivoprecaucao: c.V_108890,
	qualtipoprecaucao: c.V_108891,
	coletaexame: this.glb.getBoolean(c.V_108892),
	dataultimaColetaexame: this.glb.ddMMYYYYThhmmssToDate(c.V_108893),
	dtUltimaColeta: c.V_108894,
	tipoexame: c.V_108895,
	incontinenciaUrinaria: this.glb.getBoolean(c.V_108896),
	utilizaFraldas: this.glb.getBoolean(c.V_108897),
	periodoFraldas: c.V_108898,
	utilizaAbsorvente: this.glb.getBoolean(c.V_108899),
	periodoAbsorvente: c.V_108900,
	preferenciaspacienteCuidador: c.V_108901,
	sWITCHPrefPacCuid: c.V_108902,
	condicoesPele: c.V_108906,
	sWITCHPele: c.V_108907,
	capacidadeCognitva: c.V_108908,
	sWITCHCognitiva: c.V_108909,
	capacidadeMotora: c.V_108910,
	sWITCHMotora: c.V_108911,
	incontinencia: c.V_108912,
	sWITCHIncontinencia: c.V_108913,
	sWITCHEnvelhecimentoPele: c.V_108915,
	escoreEscalaAUFA: c.V_108916,
	envelhecimentoPele: c.V_108917,
	rESULTADOOLD: c.V_108918,
	rISCODAI: c.V_108919,
	dataICIQSF: this.glb.ddMMYYYYThhmmssToDate(c.V_108920),
	sexo: c.V_108922,
	ComQueFrequenciaVocePerdeUrina: c.V_108923,
	GostariamossaberquantidadeurinaQueVocePensaQuePerde: c.V_108924,
	EmGeralQuantoQuePerderUrinaInterfereNaSuaVidaDiariaSelecioneUmNumeroEntre0NaoInterfere10InterfereMuito: c.V_108925,
	iCIQEscore: c.V_108926,
	anteschegarAoBanheiro: this.glb.getBoolean(c.V_108927),
	quandoTussoOuEspirro: this.glb.getBoolean(c.V_108928),
	quandoEstouDormindo: this.glb.getBoolean(c.V_108929),
	quandoEstouFazendoAtividadesFisicas: this.glb.getBoolean(c.V_108930),
	quandoTermineirurinarestouMeVestindo: this.glb.getBoolean(c.V_108931),
	semRazaoObvia: this.glb.getBoolean(c.V_108932),
	oTempoTodo: this.glb.getBoolean(c.V_108933),
	nunca: this.glb.getBoolean(c.V_108934),
	pontuacao3: c.V_108935,
	pontuacao4: c.V_108936,
	rESULTADOescala: c.V_108937,
	uripen: this.glb.getBoolean(c.V_108938),
	cateterVesicaldemora: this.glb.getBoolean(c.V_108939),
	cistostomia: this.glb.getBoolean(c.V_108940),
	colostomia: this.glb.getBoolean(c.V_108941),
	gastrostomia: this.glb.getBoolean(c.V_108942),
	sondaNasoEnteral: this.glb.getBoolean(c.V_108943),
	ocorrenciaCVD: this.glb.getBoolean(c.V_108944),
	tipoCVD: c.V_108945,
	necessidadetrocadispositivoCVD: this.glb.getBoolean(c.V_108946),
	ocorrenciaCIST: this.glb.getBoolean(c.V_108947),
	tipoCIST: c.V_108948,
	necessidadetrocadispositivoCIST: this.glb.getBoolean(c.V_108949),
	ocorrenciaGAST: this.glb.getBoolean(c.V_108950),
	tipoGAST: c.V_108951,
	necessidadetrocadispositivoGAST: this.glb.getBoolean(c.V_108952),
	ocorrenciaSNE: this.glb.getBoolean(c.V_108953),
	tipoSNE: c.V_108954,
	necessidadetrocadispositivoSNE: this.glb.getBoolean(c.V_108955),
	possuiCarteiravacinacao: this.glb.getBoolean(c.V_108956),
	vacinasAtualizadas: this.glb.getBoolean(c.V_108957),
	qualVacinaPendente: c.V_108958,
	ultimaDoseRecebidaDuplaAdultoDT: this.glb.ddMMYYYYThhmmssToDate(c.V_108959),
	ultimaDoseRecebidaPneumococica23: this.glb.ddMMYYYYThhmmssToDate(c.V_108960),
	ultimaDoseRecebidaInfluenza: this.glb.ddMMYYYYThhmmssToDate(c.V_108961),
	ultimaDoseRecebidaCovid19: this.glb.ddMMYYYYThhmmssToDate(c.V_108962),
	numerohiperglicemiaNoUltimoMes: c.V_108975,
	numerohipoglicemiaNoUltimoMes: c.V_108976,
	riscoPsiquiatrico: this.glb.getBoolean(c.V_108977),
	data: this.glb.ddMMYYYYThhmmssToDate(c.V_108978),
	numeronotificacao: c.V_108979,
	conduta: c.V_108980,
	lesaoPorPressao: this.glb.getBoolean(c.V_108986),
	estagio: c.V_108987,
	numeroNotificacaoLP: c.V_108988,
	localLesaoPorPressao: c.V_108989,
	lesaoPorFriccao: this.glb.getBoolean(c.V_108990),
	grau: c.V_108991,
	numeroNotificacaoLF: c.V_108992,
	localLesaoPorFriccao: c.V_108993,
	dermatiteAssociadaincontinenciaDAI: this.glb.getBoolean(c.V_108994),
	categoria: c.V_108995,
	numeroNotificacaoHMT: c.V_108998,
	localLesao: c.V_108999,
	cornoCutaneo: this.glb.getBoolean(c.V_109000),
	criteriosPlanoLilas: c.V_109007,
	dataEvolucao7: c.V_109013,
	dataEvolucao2: c.V_109014,
	dataEvolucao3: c.V_109015,
	dataEvolucao4: c.V_109016,
	dataEvolucao6: c.V_109017,
	dataEvolucao8: c.V_109018,
	dataaplicacao: c.V_109020,
	pontuacao5: c.V_109022,
	dataEvolucao11: c.V_109024,
	dataEvolucao12: c.V_109026,
	duplaAdulto: c.V_109028,
	pneumococica: c.V_109029,
	influenza: c.V_109030,
	covid19: c.V_109031,
	dataEvolucao13: c.V_109032,
	dataEvolucao14: c.V_109035,
	dataEvolucao5: c.V_109039,
	QuandoVocePerdeUrina: c.V_109057,
	localLesaoDAI: c.V_109058,
	resultado: c.V_109059,
	executor: c.V_109064,
	riscoFuga: this.glb.getBoolean(c.V_109170),
	altoRiscoQueda: this.glb.getBoolean(c.V_109171),
	requerIsolamento: this.glb.getBoolean(c.V_109175),
	tipoIsolamento: c.V_109176,
	descreva: c.V_109177,
	hipodermoclise: this.glb.getBoolean(c.V_109178),
	retornoHospitalar: this.glb.getBoolean(c.V_109179),
	dataPrevistaRetorno: this.glb.ddMMYYYYThhmmssToDate(c.V_109180),
  SCP: c?.V_108231,
  KATZ: c?.V_108194,
  BARTHEL: c?.V_108360,
  BRADEN : c?.V_109222,
  usoFralda: this.glb.getBoolean(c.V_108897 ),
  periodoFralda: c.V_108898,
  trocas: c.V_109300,
  vasoSanitario: c.V_109302,
  campoAberto: c.V_109306,
  prevencaoQuedas: c.V_109301
     } as IEvolucaoEnfermagem;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'toAttribute', error.message);
    }
    return null;
  }

  /* Insere ou atualiza o item.
    * Se for atualização, especificar o id. Caso contrário, não fornecê-lo.
    */
  save(movimentacao: IEvolucaoEnfermagem, uno: number, id?: number): Observable<any> {
    try {
      const forms: IForm[] = this.mapToForm(movimentacao).filter(f => f.ValorDado);
      return this.cadastroSrv.save(forms, uno, this.cadastroNo, id);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'save', error.message);
    }
    return EMPTY;
  }

  protected mapToForm(evolucaoEnfermagem: IEvolucaoEnfermagem): Array<IForm> {
    try {
        return [
          { VariavelNo: 387, ValorDado: evolucaoEnfermagem.nomeresidente || '' },
          { VariavelNo: 391, ValorDado: (evolucaoEnfermagem.nascimento) },
          { VariavelNo: 1608, ValorDado: evolucaoEnfermagem.nomeMae || '' },
          { VariavelNo: 3328, ValorDado: evolucaoEnfermagem.identificacao1 || '' },
          { VariavelNo: 3329, ValorDado: evolucaoEnfermagem.identificacao2 || '' },
          { VariavelNo: 28051, ValorDado: evolucaoEnfermagem.prontuario || '' },
          { VariavelNo: 101175, ValorDado: evolucaoEnfermagem.dataAniversario || '' },
          { VariavelNo: 101305, ValorDado: evolucaoEnfermagem.dataEvolucao || '' },
          { VariavelNo: 101570, ValorDado: evolucaoEnfermagem.admissao || '' },
          { VariavelNo: 101573, ValorDado: evolucaoEnfermagem.cPF || '' },
          { VariavelNo: 101575, ValorDado: evolucaoEnfermagem.idade || '' },
          { VariavelNo: 101578, ValorDado: evolucaoEnfermagem.endereco || '' },
          { VariavelNo: 101579, ValorDado: evolucaoEnfermagem.telefone || '' },
          { VariavelNo: 101580, ValorDado: evolucaoEnfermagem.celular || '' },
          { VariavelNo: 101581, ValorDado: evolucaoEnfermagem.email || '' },
          { VariavelNo: 104082, ValorDado: evolucaoEnfermagem.pai || '' },
          { VariavelNo: 104088, ValorDado: evolucaoEnfermagem.quarto || '' },
          { VariavelNo: 105415, ValorDado: evolucaoEnfermagem.codigo || '' },
          { VariavelNo: 105416, ValorDado: evolucaoEnfermagem.titulo || '' },
          { VariavelNo: 105417, ValorDado: evolucaoEnfermagem.habilitado ? 'SIM' : 'NÃO' },
          { VariavelNo: 105988, ValorDado: (evolucaoEnfermagem.dataInternacao) },
          { VariavelNo: 108792, ValorDado: evolucaoEnfermagem.dataInternacao || '' },
          { VariavelNo: 108793, ValorDado: evolucaoEnfermagem.motivo || '' },
          { VariavelNo: 108794, ValorDado: evolucaoEnfermagem.local || '' },
          { VariavelNo: 108795, ValorDado: evolucaoEnfermagem.leitoHospitalar || '' },
          { VariavelNo: 108796, ValorDado: (evolucaoEnfermagem.dataAlta) },
          { VariavelNo: 108797, ValorDado: evolucaoEnfermagem.dataAlta || '' },
          { VariavelNo: 108798, ValorDado: evolucaoEnfermagem.diagnosticoFinal || '' },
          { VariavelNo: 108799, ValorDado: evolucaoEnfermagem.desfecho || '' },
          { VariavelNo: 108800, ValorDado: evolucaoEnfermagem.diasPermanenciaHospitalar || '' },
          { VariavelNo: 108801, ValorDado: evolucaoEnfermagem.profissionalQueIdentificoulesao || '' },
          { VariavelNo: 108803, ValorDado: evolucaoEnfermagem.ocorrenciaPRof || '' },
          { VariavelNo: 108804, ValorDado: evolucaoEnfermagem.andar || '' },
          { VariavelNo: 108805, ValorDado: (evolucaoEnfermagem.dataIncidente) },
          { VariavelNo: 108806, ValorDado: evolucaoEnfermagem.dtIncidente || '' },
          { VariavelNo: 108807, ValorDado: evolucaoEnfermagem.localIncidente || '' },
          { VariavelNo: 108811, ValorDado: evolucaoEnfermagem.hematomas ? 'SIM' : 'NÃO' },
          { VariavelNo: 108812, ValorDado: evolucaoEnfermagem.queratose ? 'SIM' : 'NÃO' },
          { VariavelNo: 108814, ValorDado: evolucaoEnfermagem.outros || '' },
          { VariavelNo: 108822, ValorDado: evolucaoEnfermagem.internacaoRecente ? 'SIM' : 'NÃO' },
          { VariavelNo: 108823, ValorDado: evolucaoEnfermagem.terminalidade ? 'SIM' : 'NÃO' },
          { VariavelNo: 108825, ValorDado: (evolucaoEnfermagem.dataAcionamento) },
          { VariavelNo: 108826, ValorDado: evolucaoEnfermagem.dtAcionamento || '' },
          { VariavelNo: 108827, ValorDado: evolucaoEnfermagem.horarioAcionamento || '' },
          { VariavelNo: 108828, ValorDado: evolucaoEnfermagem.medicoPlantonista || '' },
          { VariavelNo: 108829, ValorDado: evolucaoEnfermagem.medicoCAscata || '' },
          { VariavelNo: 108830, ValorDado: evolucaoEnfermagem.oCorrMedico || '' },
          { VariavelNo: 108831, ValorDado: evolucaoEnfermagem.cRM || '' },
          { VariavelNo: 108832, ValorDado: evolucaoEnfermagem.tipoCodigo || '' },
          { VariavelNo: 108833, ValorDado: evolucaoEnfermagem.queda ? 'SIM' : 'NÃO' },
          { VariavelNo: 108834, ValorDado: evolucaoEnfermagem.motivoAcionamento || '' },
          { VariavelNo: 108835, ValorDado: evolucaoEnfermagem.transferenciaHospitalar ? 'SIM' : 'NÃO' },
          { VariavelNo: 108836, ValorDado: evolucaoEnfermagem.houveContatomedicotelemedicinaComMedicoExterno ? 'SIM' : 'NÃO' },
          { VariavelNo: 108837, ValorDado: evolucaoEnfermagem.centrocustoidoso || '' },
          { VariavelNo: 108838, ValorDado: evolucaoEnfermagem.obito ? 'SIM' : 'NÃO' },
          { VariavelNo: 108839, ValorDado: evolucaoEnfermagem.horarioObito || '' },
          { VariavelNo: 108840, ValorDado: evolucaoEnfermagem.horariopreenchimentoDECLARACAODEOBITO || '' },
          { VariavelNo: 108841, ValorDado: (evolucaoEnfermagem.dataAplicacao) },
          { VariavelNo: 108842, ValorDado: evolucaoEnfermagem.dtAplicacao || '' },
          { VariavelNo: 108843, ValorDado: evolucaoEnfermagem.enfermeiro || '' },
          { VariavelNo: 108844, ValorDado: evolucaoEnfermagem.enfCascata || '' },
          { VariavelNo: 108845, ValorDado: evolucaoEnfermagem.opcoesScore || '' },
          { VariavelNo: 108876, ValorDado: evolucaoEnfermagem.dtData || '' },
          { VariavelNo: 108877, ValorDado: evolucaoEnfermagem.necessidadeLavagem ? 'SIM' : 'NÃO' },
          { VariavelNo: 108878, ValorDado: evolucaoEnfermagem.local || '' },
          { VariavelNo: 108879, ValorDado: (evolucaoEnfermagem.dataLavagem) },
          { VariavelNo: 108880, ValorDado: evolucaoEnfermagem.dtLavagem || '' },
          { VariavelNo: 108881, ValorDado: evolucaoEnfermagem.observacoes || '' },
          { VariavelNo: 108882, ValorDado: evolucaoEnfermagem.usaMarcapasso ? 'SIM' : 'NÃO' },
          { VariavelNo: 108883, ValorDado: (evolucaoEnfermagem.dataImplantacao) },
          { VariavelNo: 108884, ValorDado: evolucaoEnfermagem.dtImplantacao || '' },
          { VariavelNo: 108885, ValorDado: (evolucaoEnfermagem.dataultimaRevisao) },
          { VariavelNo: 108886, ValorDado: evolucaoEnfermagem.dtUltimaRevisao || '' },
          { VariavelNo: 108887, ValorDado: (evolucaoEnfermagem.dataproximaRevisao) },
          { VariavelNo: 108888, ValorDado: evolucaoEnfermagem.dtProximaRevisao || '' },
          { VariavelNo: 108889, ValorDado: evolucaoEnfermagem.encontraemPrecaucao ? 'SIM' : 'NÃO' },
          { VariavelNo: 108890, ValorDado: evolucaoEnfermagem.motivoprecaucao || '' },
          { VariavelNo: 108891, ValorDado: evolucaoEnfermagem.qualtipoprecaucao || '' },
          { VariavelNo: 108892, ValorDado: evolucaoEnfermagem.coletaexame ? 'SIM' : 'NÃO' },
          { VariavelNo: 108893, ValorDado: (evolucaoEnfermagem.dataultimaColetaexame) },
          { VariavelNo: 108894, ValorDado: evolucaoEnfermagem.dtUltimaColeta || '' },
          { VariavelNo: 108895, ValorDado: evolucaoEnfermagem.tipoexame || '' },
          { VariavelNo: 108896, ValorDado: evolucaoEnfermagem.incontinenciaUrinaria ? 'SIM' : 'NÃO' },
          { VariavelNo: 108897, ValorDado: evolucaoEnfermagem.utilizaFraldas ? 'SIM' : 'NÃO' },
          { VariavelNo: 108898, ValorDado: evolucaoEnfermagem.periodoFraldas || '' },
          { VariavelNo: 108899, ValorDado: evolucaoEnfermagem.utilizaAbsorvente ? 'SIM' : 'NÃO' },
          { VariavelNo: 108900, ValorDado: evolucaoEnfermagem.periodoAbsorvente || '' },
          { VariavelNo: 108901, ValorDado: evolucaoEnfermagem.preferenciaspacienteCuidador || '' },
          { VariavelNo: 108902, ValorDado: evolucaoEnfermagem.sWITCHPrefPacCuid || '' },
          { VariavelNo: 108906, ValorDado: evolucaoEnfermagem.condicoesPele || '' },
          { VariavelNo: 108907, ValorDado: evolucaoEnfermagem.sWITCHPele || '' },
          { VariavelNo: 108908, ValorDado: evolucaoEnfermagem.capacidadeCognitva || '' },
          { VariavelNo: 108909, ValorDado: evolucaoEnfermagem.sWITCHCognitiva || '' },
          { VariavelNo: 108910, ValorDado: evolucaoEnfermagem.capacidadeMotora || '' },
          { VariavelNo: 108911, ValorDado: evolucaoEnfermagem.sWITCHMotora || '' },
          { VariavelNo: 108912, ValorDado: evolucaoEnfermagem.incontinencia || '' },
          { VariavelNo: 108913, ValorDado: evolucaoEnfermagem.sWITCHIncontinencia || '' },
          { VariavelNo: 108915, ValorDado: evolucaoEnfermagem.sWITCHEnvelhecimentoPele || '' },
          { VariavelNo: 108916, ValorDado: evolucaoEnfermagem.escoreEscalaAUFA || '' },
          { VariavelNo: 108917, ValorDado: evolucaoEnfermagem.envelhecimentoPele || '' },
          { VariavelNo: 108918, ValorDado: evolucaoEnfermagem.rESULTADOOLD || '' },
          { VariavelNo: 108919, ValorDado: evolucaoEnfermagem.rISCODAI || '' },
          { VariavelNo: 108920, ValorDado: (evolucaoEnfermagem.dataICIQSF) },
          { VariavelNo: 108921, ValorDado: evolucaoEnfermagem.dtData || '' },
          { VariavelNo: 108922, ValorDado: evolucaoEnfermagem.sexo || '' },
          { VariavelNo: 108923, ValorDado: evolucaoEnfermagem.ComQueFrequenciaVocePerdeUrina || '' },
          { VariavelNo: 108924, ValorDado: evolucaoEnfermagem.GostariamossaberquantidadeurinaQueVocePensaQuePerde || '' },
          { VariavelNo: 108925, ValorDado: evolucaoEnfermagem.EmGeralQuantoQuePerderUrinaInterfereNaSuaVidaDiariaSelecioneUmNumeroEntre0NaoInterfere10InterfereMuito || '' },
          { VariavelNo: 108926, ValorDado: evolucaoEnfermagem.iCIQEscore || '' },
          { VariavelNo: 108927, ValorDado: evolucaoEnfermagem.anteschegarAoBanheiro ? 'SIM' : 'NÃO' },
          { VariavelNo: 108928, ValorDado: evolucaoEnfermagem.quandoTussoOuEspirro ? 'SIM' : 'NÃO' },
          { VariavelNo: 108929, ValorDado: evolucaoEnfermagem.quandoEstouDormindo ? 'SIM' : 'NÃO' },
          { VariavelNo: 108930, ValorDado: evolucaoEnfermagem.quandoEstouFazendoAtividadesFisicas ? 'SIM' : 'NÃO' },
          { VariavelNo: 108931, ValorDado: evolucaoEnfermagem.quandoTermineirurinarestouMeVestindo ? 'SIM' : 'NÃO' },
          { VariavelNo: 108932, ValorDado: evolucaoEnfermagem.semRazaoObvia ? 'SIM' : 'NÃO' },
          { VariavelNo: 108933, ValorDado: evolucaoEnfermagem.oTempoTodo ? 'SIM' : 'NÃO' },
          { VariavelNo: 108934, ValorDado: evolucaoEnfermagem.nunca ? 'SIM' : 'NÃO' },
          { VariavelNo: 108935, ValorDado: evolucaoEnfermagem.pontuacao3 || '' },
          { VariavelNo: 108936, ValorDado: evolucaoEnfermagem.pontuacao4 || '' },
          { VariavelNo: 108937, ValorDado: evolucaoEnfermagem.rESULTADOescala || '' },
          { VariavelNo: 108938, ValorDado: evolucaoEnfermagem.uripen ? 'SIM' : 'NÃO' },
          { VariavelNo: 108939, ValorDado: evolucaoEnfermagem.cateterVesicaldemora ? 'SIM' : 'NÃO' },
          { VariavelNo: 108940, ValorDado: evolucaoEnfermagem.cistostomia ? 'SIM' : 'NÃO' },
          { VariavelNo: 108941, ValorDado: evolucaoEnfermagem.colostomia ? 'SIM' : 'NÃO' },
          { VariavelNo: 108942, ValorDado: evolucaoEnfermagem.gastrostomia ? 'SIM' : 'NÃO' },
          { VariavelNo: 108943, ValorDado: evolucaoEnfermagem.sondaNasoEnteral ? 'SIM' : 'NÃO' },
          { VariavelNo: 108944, ValorDado: evolucaoEnfermagem.ocorrenciaCVD ? 'SIM' : 'NÃO' },
          { VariavelNo: 108945, ValorDado: evolucaoEnfermagem.tipoCVD || '' },
          { VariavelNo: 108946, ValorDado: evolucaoEnfermagem.necessidadetrocadispositivoCVD ? 'SIM' : 'NÃO' },
          { VariavelNo: 108947, ValorDado: evolucaoEnfermagem.ocorrenciaCIST ? 'SIM' : 'NÃO' },
          { VariavelNo: 108948, ValorDado: evolucaoEnfermagem.tipoCIST || '' },
          { VariavelNo: 108949, ValorDado: evolucaoEnfermagem.necessidadetrocadispositivoCIST ? 'SIM' : 'NÃO' },
          { VariavelNo: 108950, ValorDado: evolucaoEnfermagem.ocorrenciaGAST ? 'SIM' : 'NÃO' },
          { VariavelNo: 108951, ValorDado: evolucaoEnfermagem.tipoGAST || '' },
          { VariavelNo: 108952, ValorDado: evolucaoEnfermagem.necessidadetrocadispositivoGAST ? 'SIM' : 'NÃO' },
          { VariavelNo: 108953, ValorDado: evolucaoEnfermagem.ocorrenciaSNE ? 'SIM' : 'NÃO' },
          { VariavelNo: 108954, ValorDado: evolucaoEnfermagem.tipoSNE || '' },
          { VariavelNo: 108955, ValorDado: evolucaoEnfermagem.necessidadetrocadispositivoSNE ? 'SIM' : 'NÃO' },
          { VariavelNo: 108956, ValorDado: evolucaoEnfermagem.possuiCarteiravacinacao ? 'SIM' : 'NÃO' },
          { VariavelNo: 108957, ValorDado: evolucaoEnfermagem.vacinasAtualizadas ? 'SIM' : 'NÃO' },
          { VariavelNo: 108958, ValorDado: evolucaoEnfermagem.qualVacinaPendente || '' },
          { VariavelNo: 108959, ValorDado: (evolucaoEnfermagem.ultimaDoseRecebidaDuplaAdultoDT) },
          { VariavelNo: 108960, ValorDado: (evolucaoEnfermagem.ultimaDoseRecebidaPneumococica23) },
          { VariavelNo: 108961, ValorDado: (evolucaoEnfermagem.ultimaDoseRecebidaInfluenza) },
          { VariavelNo: 108962, ValorDado: (evolucaoEnfermagem.ultimaDoseRecebidaCovid19) },
          { VariavelNo: 108975, ValorDado: evolucaoEnfermagem.numerohiperglicemiaNoUltimoMes || '' },
          { VariavelNo: 108976, ValorDado: evolucaoEnfermagem.numerohipoglicemiaNoUltimoMes || '' },
          { VariavelNo: 108977, ValorDado: evolucaoEnfermagem.riscoPsiquiatrico ? 'SIM' : 'NÃO' },
          { VariavelNo: 108978, ValorDado: (evolucaoEnfermagem.data) },
          { VariavelNo: 108979, ValorDado: evolucaoEnfermagem.numeronotificacao || '' },
          { VariavelNo: 108980, ValorDado: evolucaoEnfermagem.conduta || '' },
          { VariavelNo: 108986, ValorDado: evolucaoEnfermagem.lesaoPorPressao ? 'SIM' : 'NÃO' },
          { VariavelNo: 108987, ValorDado: evolucaoEnfermagem.estagio || '' },
          { VariavelNo: 108988, ValorDado: evolucaoEnfermagem.numeroNotificacaoLP || '' },
          { VariavelNo: 108989, ValorDado: evolucaoEnfermagem.localLesaoPorPressao || '' },
          { VariavelNo: 108990, ValorDado: evolucaoEnfermagem.lesaoPorFriccao ? 'SIM' : 'NÃO' },
          { VariavelNo: 108991, ValorDado: evolucaoEnfermagem.grau || '' },
          { VariavelNo: 108992, ValorDado: evolucaoEnfermagem.numeroNotificacaoLF || '' },
          { VariavelNo: 108993, ValorDado: evolucaoEnfermagem.localLesaoPorFriccao || '' },
          { VariavelNo: 108994, ValorDado: evolucaoEnfermagem.dermatiteAssociadaincontinenciaDAI ? 'SIM' : 'NÃO' },
          { VariavelNo: 108995, ValorDado: evolucaoEnfermagem.categoria || '' },
          { VariavelNo: 108998, ValorDado: evolucaoEnfermagem.numeroNotificacaoHMT || '' },
          { VariavelNo: 108999, ValorDado: evolucaoEnfermagem.localLesao || '' },
          { VariavelNo: 109000, ValorDado: evolucaoEnfermagem.cornoCutaneo ? 'SIM' : 'NÃO' },
          { VariavelNo: 109007, ValorDado: evolucaoEnfermagem.criteriosPlanoLilas || '' },
          { VariavelNo: 109013, ValorDado: evolucaoEnfermagem.dataEvolucao7 || '' },
          { VariavelNo: 109014, ValorDado: evolucaoEnfermagem.dataEvolucao2 || '' },
          { VariavelNo: 109015, ValorDado: evolucaoEnfermagem.dataEvolucao3 || '' },
          { VariavelNo: 109016, ValorDado: evolucaoEnfermagem.dataEvolucao4 || '' },
          { VariavelNo: 109017, ValorDado: evolucaoEnfermagem.dataEvolucao6 || '' },
          { VariavelNo: 109018, ValorDado: evolucaoEnfermagem.dataEvolucao8 || '' },
          { VariavelNo: 109020, ValorDado: evolucaoEnfermagem.dataaplicacao || '' },
          { VariavelNo: 109022, ValorDado: evolucaoEnfermagem.pontuacao5 || '' },
          { VariavelNo: 109024, ValorDado: evolucaoEnfermagem.dataEvolucao11 || '' },
          { VariavelNo: 109026, ValorDado: evolucaoEnfermagem.dataEvolucao12 || '' },
          { VariavelNo: 109028, ValorDado: evolucaoEnfermagem.duplaAdulto || '' },
          { VariavelNo: 109029, ValorDado: evolucaoEnfermagem.pneumococica || '' },
          { VariavelNo: 109030, ValorDado: evolucaoEnfermagem.influenza || '' },
          { VariavelNo: 109031, ValorDado: evolucaoEnfermagem.covid19 || '' },
          { VariavelNo: 109032, ValorDado: evolucaoEnfermagem.dataEvolucao13 || '' },
          { VariavelNo: 109035, ValorDado: evolucaoEnfermagem.dataEvolucao14 || '' },
          { VariavelNo: 109039, ValorDado: evolucaoEnfermagem.dataEvolucao5 || '' },
          { VariavelNo: 109057, ValorDado: evolucaoEnfermagem.QuandoVocePerdeUrina || '' },
          { VariavelNo: 109058, ValorDado: evolucaoEnfermagem.localLesaoDAI || '' },
          { VariavelNo: 109059, ValorDado: evolucaoEnfermagem.resultado || '' },
          { VariavelNo: 109064, ValorDado: evolucaoEnfermagem.executor || '' },
          { VariavelNo: 109065, ValorDado: (evolucaoEnfermagem.data) },
          { VariavelNo: 109170, ValorDado: evolucaoEnfermagem.riscoFuga ? 'SIM' : 'NÃO' },
          { VariavelNo: 109171, ValorDado: evolucaoEnfermagem.altoRiscoQueda ? 'SIM' : 'NÃO' },
          { VariavelNo: 109175, ValorDado: evolucaoEnfermagem.requerIsolamento ? 'SIM' : 'NÃO' },
          { VariavelNo: 109176, ValorDado: evolucaoEnfermagem.tipoIsolamento || '' },
          { VariavelNo: 109177, ValorDado: evolucaoEnfermagem.descreva || '' },
          { VariavelNo: 109178, ValorDado: evolucaoEnfermagem.hipodermoclise ? 'SIM' : 'NÃO' },
          { VariavelNo: 109179, ValorDado: evolucaoEnfermagem.retornoHospitalar ? 'SIM' : 'NÃO' },
          { VariavelNo: 109180, ValorDado: (evolucaoEnfermagem.dataPrevistaRetorno) },
              ];
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapToForm', error.message);
    }
    return null;
  }

}
