
import { refCount, publishReplay, map, filter, toArray } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { IMovimentacaoDeLeito } from '@medlogic/shared/shared-interfaces';
import { Observable, of } from 'rxjs';
import { EMPTY } from 'rxjs';
import { GlobalService, LogService, IForm } from '@medlogic/shared/shared-interfaces';
import { CadastroService } from '@medlogic/shared/shared-data-access';

export abstract class MovimentacaoDeLeitoService {

  // tslint:disable-next-line: max-line-length
  protected lstVariaveis = 'V_387,V_28051,V_107158,V_107276,V_107277,V_107278,V_107279,V_107280,V_107281,V_107282,V_107283,V_107284,V_107285,V_107286,V_107287,V_109160,V_109162';
  private variavelGrid = '';
  private lstVariaveisGrid = '';

  recurrences: Array<IMovimentacaoDeLeito> = new Array<IMovimentacaoDeLeito>();

  cadastroNo = 24638;
  currentDtInicial = new Date();
  currentDtFinal = new Date();
  cadastrosCache: Observable<any>;


  constructor(
    protected http: HttpClient,
    protected cadastroSrv: CadastroService,
    protected glb: GlobalService,
    protected log: LogService) {
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'constructor', error.message);
    }
  }

  // TODO: Atenção: o correto não é retornar todos os dados, de todas as datas e depois filtrar, mas enviar a data como parâmetro.
  getAll(ano: number, startDate?: Date, endDate?: Date): Observable<IMovimentacaoDeLeito> {
    try {
      this.cadastroNo = ano;
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      return this.getWithCache(this.cadastroNo, startDate, endDate).pipe(
        filter(f => {
          return this.glb.isBetweenIgnoreTime(f.dataMovimentacao, startDate, endDate);
        }));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAll', error.message);
    }
    return null;
  }

  /* Método utilizado para popular uma lista com os itens ativos. */
  loadArray(ano: number): Observable<any> {
    try {
      const propLabel = 'titulo'; const propValue = 'codigo'; const propEnabled = 'habilitado';
      return this.cadastroSrv.loadArray(this.getAll(ano), propLabel, propValue, propEnabled);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'loadArray', error.message);
    }
  }

  /* Limpa o cache de forma que a próxima chamada buscará os dados do serviço novamente. */
  clearCache(): void {
    try {
      this.cadastrosCache = null;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'clearCache', error.message);
    }
  }

  protected getWithCache(cadastroNo: number, startDate: Date, endDate: Date): Observable<IMovimentacaoDeLeito> {
    if (
      (startDate.getTime() !== this.currentDtInicial.getTime())
      || (endDate.getTime() !== this.currentDtFinal.getTime())
      || (!this.cadastrosCache)
    ) {
      this.currentDtInicial = startDate;
      this.currentDtFinal = endDate;
      this.cadastrosCache = this.getFromCadastro(cadastroNo, startDate, endDate);
    } else {
      console.log('retorno do cache');
    }
    return this.cadastrosCache;
  }

  protected getFromCadastro(cadastroNo: number, startDate: Date, endDate: Date): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv
        .getCadastro(cadastroNo, this.lstVariaveis).pipe(
          map(m => this.toAttribute(m)),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadatro', error.message);
    }
    return null;
  }

  /* Converte o objeto que vem do serviço para IVitalSign. */
  protected toAttribute(c: any): IMovimentacaoDeLeito {
    try {
      return {
        ocorrenciaNo: c.OcorrenciaNo,
        residente: c.V_387,
        prontuarioMEDLOGIC: c.V_28051,
        prontuarioEinstein: c.V_107158,
        codigo: c.V_107276,
        titulo: c.V_107277,
        habilitado: this.glb.getBoolean(c.V_107278),
        bloco: c.V_107279,
        andarBlocoPROC: c.V_107280,
        quarto: c.V_107281,
        tipoQuarto: c.V_107282,
        leito: c.V_107283,
        ramalPROC: c.V_107284,
        ramal: c.V_107285,
        justificativa: c.V_107286,
        dataMovimentacao: this.glb.ddMMYYYYThhmmssToDate(c.V_107287),
        andar: c.V_109160,
        andarMaisRecente: c.V_109162,

     } as IMovimentacaoDeLeito;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'toAttribute', error.message);
    }
    return null;
  }

  /* Insere ou atualiza o item.
    * Se for atualização, especificar o id. Caso contrário, não fornecê-lo.
    */
  save(movimentacao: IMovimentacaoDeLeito, uno: number, id?: number): Observable<any> {
    try {
      const forms: IForm[] = this.mapToForm(movimentacao).filter(f => f.ValorDado);
      return this.cadastroSrv.save(forms, uno, this.cadastroNo, id);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'save', error.message);
    }
    return EMPTY;
  }

  protected mapToForm(movimentacaoDeLeito: IMovimentacaoDeLeito): Array<IForm> {
    try {
        return [
           { VariavelNo: 387, ValorDado: movimentacaoDeLeito.residente || '' },
            { VariavelNo: 28051, ValorDado: movimentacaoDeLeito.prontuarioMEDLOGIC || '' },
            { VariavelNo: 107158, ValorDado: movimentacaoDeLeito.prontuarioEinstein || '' },
            { VariavelNo: 107276, ValorDado: movimentacaoDeLeito.codigo || '' },
            { VariavelNo: 107277, ValorDado: movimentacaoDeLeito.titulo || '' },
            { VariavelNo: 107278, ValorDado: movimentacaoDeLeito.habilitado ? 'SIM' : 'NÃO' },
            { VariavelNo: 107279, ValorDado: movimentacaoDeLeito.bloco || '' },
            { VariavelNo: 107280, ValorDado: movimentacaoDeLeito.andarBlocoPROC || '' },
            { VariavelNo: 107281, ValorDado: movimentacaoDeLeito.quarto || '' },
            { VariavelNo: 107282, ValorDado: movimentacaoDeLeito.tipoQuarto || '' },
            { VariavelNo: 107283, ValorDado: movimentacaoDeLeito.leito || '' },
            { VariavelNo: 107284, ValorDado: movimentacaoDeLeito.ramalPROC || '' },
            { VariavelNo: 107285, ValorDado: movimentacaoDeLeito.ramal || '' },
            { VariavelNo: 107286, ValorDado: movimentacaoDeLeito.justificativa || '' },
            { VariavelNo: 107287, ValorDado: this.glb.ddMMYYYYThhmmssToDate(movimentacaoDeLeito.dataMovimentacao) },
            { VariavelNo: 109160, ValorDado: movimentacaoDeLeito.andar || '' },
            { VariavelNo: 109162, ValorDado: movimentacaoDeLeito.andarMaisRecente || '' },
              ];
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapToForm', error.message);
    }
    return null;
  }

  /* Retorna um título bem formatado para cada campo.  */
  getRotulo(field: string): string {
    try {
      const mapR = {
        codigoPaciente: '#NAOEXIBIR#',
        dataavaliacao: '#NAOEXIBIR#',
        codigo: '#NAOEXIBIR#',
        titulo: '#NAOEXIBIR#',
        habilitado: '#NAOEXIBIR#',
        laudo: 'Laudo',
        oCORRENCIA: '#NAOEXIBIR#',
        codPacCAT: '#NAOEXIBIR#',
        codPacOcorrenciaCAT: '#NAOEXIBIR#',
        executorAvaliacao: '#NAOEXIBIR#',
        executorMedicaoData: '#NAOEXIBIR#',
        codPacPressao: '#NAOEXIBIR#',
        cODHOSPDATA: '#NAOEXIBIR#',
        tecnicoResponsavel: 'Profissional',
        frequenciaCardiacaBpm: 'Frequência Cardíaca (BPM)',
        frequenciaRespiratoriaICP: 'Frequência Respiratória (ICP)',
        glicemiaCapilarEmJejumMlDl: '(Glicemia Capilar em Jejum (ml/dl)',
        posPrandialMlDl: 'Glicemia pós-prandial (ml/dl)',
        glicose: 'Glicose',
        pADiastolicaRef80: 'Pressão Diastólica (ref: 80)',
        pASistolicaRef120: 'Pressão Sistólica (ref: 120)',
        saturacaoOxigenioSO: 'Saturação de Oxigênio',
        temperaturaTax: 'Temperatura'
      };
      if (mapR.hasOwnProperty(field)) {
        return mapR[field];
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getRotulo', error.message);
    }
    return '';
  }

}
