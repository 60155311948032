import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { AvaliacaoNutricionalCustomService, EvolucaoEnfermagemCustomService, EvolucaoFisioterapiaCustomService, EvolucaoFonoCustomService, MovimentacaoDeLeitoCustomService, PatientCustomService } from '@medlogic/medlogic/medlogic-data-access';
import { IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { selectedPatient, selectedPatientId } from '@medlogic/medlogic/medlogic-state';
import { GlobalService, IPatient, LogService, UnsubscribeOnDestroyAdapter } from '@medlogic/shared/shared-interfaces';
import { Store, select } from '@ngrx/store';
import { Observable, map, mergeMap, take, takeLast, tap } from 'rxjs';
import { NavigationService } from '../../service/navigation.service';
import { ConfigPwaMedLogicService } from '../../service/config-pwa-medlogic.custom.service';
import { EvolucaoTerapiaOcupaionalCustomService } from '../../../../../../../medlogic/medlogic-data-access/src/lib/evolucao-terapia-ocupacional.custom.service'
import { CareRoutineCustomService } from '../../../../../../../medlogic/medlogic-data-access/src/lib/care-routine.custom.service'
import { FhirPatientIconsService, loadActivities } from '@medlogic/fhir';
import { MatDialog } from '@angular/material/dialog';
import { UiDialogAlertComponent } from '@medlogic/shared/ui/dialog/ui-dialog-alert';


@Component({
  selector: 'ml-care-plan-report',
  templateUrl: './care-plan-report.component.html',
  styleUrls: ['./care-plan-report.component.css']
})
export class CarePlanReportComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  selectedPatientId$: Observable<number> = this.store.pipe(select(selectedPatientId));

  quarto: any;
  bloco: any;
  fluxoOxigenio: any;
  periodoutilizacaoContinuo: String;
  periodoutilizacaoAlimentacao: String;
  periodoutilizacaoNoturno: String;
  periodoutilizacaoBanho: String;
  periodoutilizacaoDeambulacao: String;
  todosOsMomentos: any;
  auxilioDispositivo: any;
  sairEntrarNoLeito: any;
  auxilioDispositivo2: any;
  cadeiraCadeira: any;
  auxilioDispositivo3: any;
  marchas: any[] = [];
  todasAsDependencias: any;
  areasExternas: any;
  areasInternas: any;
  andarMoradia: any;
  foraAndarMoradia: any;
  dentroQuarto: any;
  usoFralda: any;
  periodoFralda: any;
  ofertaOral: any;
  tipo: any;
  localRefeicao: any;
  mudancaDieta: any;
  refeicaoAdaptada: any;
  usoEspessante: any;
  consistencia: any;
  proporcaoEspessante: any;
  campoAbertoFono: any;
  cpap: any;
  cadeiraHigienica: any;
  auxilioFono: any;
  campoAbertoFono2: any;
  suspender: any;
  higieneOralUsoProtese: any;
  pentear: any;
  barbaMaquiagem: any;
  auxilioTO: any;
  superiores: any;
  inferiores: any;
  campoAbertoTO: any;
  campoAbertoEnf: any;
  trocas: any;
  vasoSanitario: any;
  campoAbertoFono3: any;
  comunicacao: any;
  prevencaoQuedas: any;
  comportamento: any;
  rotinaGeral: any;
  usoOxigenio: any;
  campoAbertoTransf: any;
  consistenciaEspessante: any;
  campoAbertoOxi: any;
  campoAbertoLoc: any;
  patientId: any;
  selectedPatient: IPatient;
  prontuario: any;
  isLoading: boolean = true;
  descrevaRefeicao: any;
  patientIcons: any[] = [];

  public get patient(): IPatient {
    return this.pwa.selectedPatient;
  }

  selectedPatient$: Observable<IPatient> = this.store.select(selectedPatient);


  constructor(
    protected log: LogService,
    protected glb: GlobalService,
    protected cnf: LogService,
    protected http: HttpClient,
    protected leitoSrv: MovimentacaoDeLeitoCustomService,
    protected fisioterapiaSrv: EvolucaoFisioterapiaCustomService,
    protected enfermagemSrv: EvolucaoEnfermagemCustomService,
    protected fonoaudiologiaSrv: EvolucaoFonoCustomService,
    protected terapiaOcupacionalSrv: EvolucaoTerapiaOcupaionalCustomService,
    protected careRoutineSrv: CareRoutineCustomService,
    protected store: Store<IAppMedlogicState>,
    private nav: NavigationService,
    private pwa: ConfigPwaMedLogicService,
    protected nutricionalSrv: AvaliacaoNutricionalCustomService,
    private matDialog: MatDialog,

  ) {
    super();
  }

  ngOnInit(): void {
    try {
      this.subs.sink = this.selectedPatientId$.pipe(tap(id => this.patientId = id)).subscribe();
      this.store.select(selectedPatient).pipe(take(1)).subscribe(res => this.selectedPatient = res);
      this.prontuario = this.patientId;
      this.carregaLeito();
      this.carregaFisioterapia();
      this.carregaEnfermagem();
      this.carregaFono();
      this.carregaTO();
      this.carregaComportamento();
      this.carregaRotinaCuidados();
      this.carregaNutri();
    } catch (_error: any) {
      this.log.Registrar(this.constructor.name, "ngOnInit", _error.message);
    }
  }

  carregaLeito(): void {
    try {
      this.subs.sink = this.leitoSrv
        .getLocationByMedlogicId(this.patientId)
        .pipe(
          tap((leito) => {
            this.quarto = leito.quarto,
            this.bloco = leito.bloco
          }))
        .subscribe();
    } catch (_error: any) {
      this.log.Registrar(this.constructor.name, "carregaLeito", _error.message);
    }
  }

  carregaFisioterapia(): void {
    try {
      this.subs.sink = this.fisioterapiaSrv
        .getByIdAndPeriod(24561, this.patientId)
        .pipe(
          tap((fisio) => {
            this.fluxoOxigenio = fisio?.fluxoOxigenio2;
            this.usoOxigenio = this.mapBooleanToString(fisio?.usoOxigenio) === "Sim" ? true : false;;
            this.periodoutilizacaoContinuo = fisio?.periodoutilizacaoContinuo == true ? "Todos momentos" : "";
            this.periodoutilizacaoAlimentacao = fisio?.periodoutilizacaoAlimentacao == true ? "Alimentação" : "";
            this.periodoutilizacaoNoturno = fisio?.periodoutilizacaoNoturno == true ? "Sono" : "";
            this.periodoutilizacaoBanho = fisio?.periodoutilizacaoBanho == true ? "Banho" : "";
            this.periodoutilizacaoDeambulacao = fisio?.periodoutilizacaoDeambulacao == true ? "Deambulação" : "";
            this.todosOsMomentos = fisio?.todosOsMomentos;
            this.auxilioDispositivo = fisio?.auxilioDispositivo;
            this.sairEntrarNoLeito = fisio?.sairEntrarNoLeito;
            this.auxilioDispositivo2 = fisio?.auxilioDispositivo2;
            this.cadeiraCadeira = fisio?.cadeiraCadeira;
            this.auxilioDispositivo3 = fisio?.auxilioDispositivo3;
            for (let i = 0; i <= 6; i++) {
              let prop = `dispositivomarcha${i}`;

              if (i == 0) prop = `dispositivomarcha`;

              this.marchas[i] = fisio[prop];
            }
            this.todasAsDependencias = fisio?.todasAsDependenciasRIAE;
            this.areasExternas = fisio?.areasExternasRIAE;
            this.areasInternas = fisio?.areasInternasRIAE;
            this.andarMoradia = fisio?.andarmoradia;
            this.foraAndarMoradia = fisio?.foraandarmoradia;
            this.dentroQuarto = fisio?.dentroquarto;
            this.cpap = fisio?.cpap;
            this.campoAbertoOxi = fisio?.outros;
            this.campoAbertoTransf = fisio?.outros2;
            this.campoAbertoLoc = fisio?.campoAbertoLoc;
          })
        )
        .subscribe();
    } catch (_error: any) {
      this.log.Registrar(
        this.constructor.name,
        "carregaFisioterapia",
        _error.message
      );
    }
  }

  carregaEnfermagem(): void {
    try {
      this.subs.sink = this.enfermagemSrv
        .getByIdAndPeriod(24567, this.patientId)
        .pipe(
          tap((enf) => {
            this.usoFralda = this.mapBooleanToString(enf?.usoFralda) === "Sim" ? true : false;
            this.periodoFralda =  enf?.periodoFralda;
            this.trocas = enf?.trocas;
            this.vasoSanitario = enf?.vasoSanitario;
            this.campoAbertoEnf = enf?.campoAberto;
            this.prevencaoQuedas = enf?.prevencaoQuedas;
          }
          ))
        .subscribe();
    } catch (_error: any) {
      this.log.Registrar(
        this.constructor.name,
        "carregaEnfermagem",
        _error.message
      );
    }
  }

  carregaFono(): void {
    try {
      this.subs.sink = this.fonoaudiologiaSrv
        .getByIdAndPeriod(24665, this.patientId)
        .pipe(
          tap((fono) => {
            this.ofertaOral = fono?.ofertaOral;
            this.tipo = fono?.tipo;
            this.localRefeicao = fono?.localRefeicao;
            this.mudancaDieta = this.mapBooleanToString(fono?.mudancaDieta);
            this.refeicaoAdaptada = this.mapBooleanToString(fono?.refeicaoAdaptada) === "Sim" ? true : false;
            this.usoEspessante = fono?.usoEspessante === "Sim" ? true : false;
            this.consistencia = fono?.concistencia;
            this.proporcaoEspessante = fono?.proporcaoEspessante;
            this.campoAbertoFono = fono?.campoAberto;
            this.cadeiraHigienica = fono?.cadeiraHigienica;
            this.auxilioFono = fono?.auxilio;
            this.campoAbertoFono2 = fono?.campoAberto2;
            this.suspender = this.mapBooleanToString(fono?.suspenderDieta) === "Sim" ? true : false;
            this.comunicacao = fono?.comunicacao;
            this.campoAbertoFono3 = fono?.campoAberto3;  
            this.consistenciaEspessante = fono?.consistenciaEspessante;
            this.descrevaRefeicao = fono?.descreva;

            if(fono?.usoEspessante)
            this.patientIcons.push({ display: 'espessante', description: "Uso de Espessante" });
          })
        )
        .subscribe();
    } catch (_error: any) {
      this.log.Registrar(this.constructor.name, "carregaFono", _error.message);
    }
  }

  carregaTO(): void {
    try {
      this.subs.sink = this.terapiaOcupacionalSrv
        .getByIdAndPeriod(24560, this.patientId)
        .pipe(
          tap((to) => {
            this.higieneOralUsoProtese = to?.higieneOralUsoProtese;
            this.pentear = to?.pentear;
            this.barbaMaquiagem = to?.barbaMaquiagem;
            this.auxilioTO = to?.auxilio;
            this.superiores = to?.superiores;
            this.inferiores = to?.inferiores;
            this.campoAbertoTO = to?.campoAberto;
          })
        )
        .subscribe();
    } catch (_error: any) {
      this.log.Registrar(this.constructor.name, "carregaTO", _error.message);
    }
  }


  carregaComportamento(): void{
    try {
      const url = `http://localhost:3333/api/PatientIcons/getBehaviourStatus?medlogicId=${this.patientId}`;

      this.subs.sink = this.http.get<any>(url).subscribe(
        (response) => {
          switch (response?.display) {
            case "residente-normal":
              this.comportamento = "Tranquilo";
              break;

            case "residente-alteracao-leve":
              this.comportamento = "Agitado";
              break;

            case "residente-normal":
              this.comportamento = "Agressivo";
              break;
          }
        },
        (error) => {
          console.log("Erro: ", error);
        }
      );

        
    } catch (_error: any) {
      this.log.Registrar(this.constructor.name, "carregaComportamento", _error.message);
    }
  }

  carregaRotinaCuidados(): void {
    try {
      this.subs.sink = this.careRoutineSrv
        .getByIdAndPeriod(24420, this.patientId)
        .pipe(
          tap((routine) => {
            this.rotinaGeral = routine[routine.length - 1]?.outrasOrientacoes;
            this.isLoading = false;
          }))
        .subscribe();
    } catch (_error: any) {
      this.log.Registrar(this.constructor.name, "carregaRotinaCuidados()", _error.message);
    }
  }

  carregaNutri(): void {
    try {
      this.subs.sink = this.nutricionalSrv
        .getByIdAndPeriod(24569, this.patientId)
        .pipe(
          tap((nutri) => {
          if(nutri?.diabetico)
            this.patientIcons.push({ display: 'diabetico', description: "Diabético" });
          })
        )
        .subscribe();
    } catch (_error: any) {
      this.log.Registrar(this.constructor.name, "carregaNutri()", _error.message);
    }
  }

  mapBooleanToString(bool: boolean): String {
    return bool == true ? "Sim" : "Não";
  }

  alerta(icon: { display: string, description: string }){
    this.matDialog.open(UiDialogAlertComponent, {
      width: '300px',
      maxWidth: '520px',
      height: '200px',
      data: {
        messageHtml: `<h2>${icon.description}</h2>`,
      }
    })
  }

  /* Retorna para a página anterior. */
  onBack(args: any): void {
    try {
      this.nav.gotoRoot(this.pwa.tenantId);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onBack', error.message);
    }
  }

}



