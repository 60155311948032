import { refCount, publishReplay, map, tap, filter } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { ICareRoutine } from "../../../../shared/shared-interfaces/src/lib/interface/icare-routine";
import { LibService } from "@medlogic/shared/geform";
import { CadastroService } from "@medlogic/shared/shared-data-access";
import { GlobalService, LogService, IForm } from "@medlogic/shared/shared-interfaces";
import { ConfigStateService } from "@medlogic/shared/state-config";


@Injectable()
export class CareRoutineService {
  protected lstVariaveis =
    "V_387,V_28051,V_31928,V_31929,V_31930,V_31931,V_31932,V_31933,V_31941,V_31942,V_31946,V_109235,V_109307";
  private variavelGrid = "";
  private lstVariaveisGrid = "";

  recurrences: Array<ICareRoutine> = new Array<ICareRoutine>();

  cadastroNo = 24422;
  currentDtInicial: Date = new Date();
  currentDtFinal: Date = new Date();
  cadastrosCache: Observable<any>;

  constructor(
    protected http: HttpClient,
    protected cadastroSrv: CadastroService,
    protected glb: GlobalService,
    private cnf: ConfigStateService,
    protected log: LogService,
    protected lib: LibService
  ) {
    try {
    } catch (_error: any) {
      this.log.Registrar(this.constructor.name, "constructor", _error.message);
    }
  }

  // TODO: Atenção: o correto não é retornar todos os dados, de todas as datas e depois filtrar, mas enviar a data como parâmetro.
  getAll(startDate?: Date, endDate?: Date): Observable<any> {
    try {
        startDate = startDate || new Date(1900, 0, 1);
        endDate = endDate || new Date(2500, 0, 1);
        return this.getWithCache(this.cadastroNo, startDate, endDate).pipe(tap(x=>console.log(x)));
    } catch (_error: any) {
        this.log.Registrar(this.constructor.name, 'getAll', _error.message);
    }
    return null;
}

  /* Método utilizado para popular uma lista com os itens ativos. */
  loadArray(ano: number): Observable<any> {
    try {
      const propLabel = 'titulo'; const propValue = 'codigo'; const propEnabled = 'habilitado';
      return this.cadastroSrv.loadArray(this.getAll(), propLabel, propValue, propEnabled);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'loadArray', error.message);
    }
  }

  /* Limpa o cache de forma que a próxima chamada buscará os dados do serviço novamente. */
  clearCache(): void {
    try {
      this.cadastrosCache = null;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'clearCache', error.message);
    }
  }

  protected getWithCache(cadastroNo: number, startDate: Date, endDate: Date): Observable<ICareRoutine> {
    if (
      (startDate.getTime() !== this.currentDtInicial.getTime())
      || (endDate.getTime() !== this.currentDtFinal.getTime())
      || (!this.cadastrosCache)
    ) {
      this.currentDtInicial = startDate;
      this.currentDtFinal = endDate;
      this.cadastrosCache = this.getFromCadastro(cadastroNo, startDate, endDate);
    } else {
      console.log('retorno do cache');
    }
    return this.cadastrosCache;
  }

  protected getFromCadastro(cadastroNo: number, startDate: Date, endDate: Date): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv
        .getCadastro(cadastroNo, this.lstVariaveis).pipe(
          map(m => this.toAttribute(m)),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadatro', error.message);
    }
    return null;
  }

  protected toAttribute(c: any): ICareRoutine {
    // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
    try {
      return {
        dataEvolucao: c.V_109235,
        ocorrenciaNo: c.OcorrenciaNo,
        residente: c.V_387,
        prontuario: c.V_28051,
        codigo: c.V_31928,
        titulo: c.V_31929,
        habilitado: this.lib.getBoolean(c.V_31930),
        hospede: c.V_31931,
        hora: c.V_31932,
        diasSemana: c.V_31933,
        tipo: c.V_31941,
        descricao: c.V_31942,
        profissional: c.V_31946,
        outrasOrientacoes: c.V_109307,
      } as ICareRoutine;
    } catch (_error: any) {
      this.log.Registrar(
        this.constructor.name,
        "getFromCadatro",
        _error.message
      );
    }
    return null;
  }

  /** Insere ou atualiza o item.
   * Se for atualização, especificar o id. Caso contrário, não fornecê-lo.
   */
  save(careRoutine: ICareRoutine, id?: number): Observable<any> {
    try {
      const uno = this.cnf.usuarioLogadoNo;
      const forms: Array<IForm> = this.mapToForm(careRoutine).filter(
        (f) => f.ValorDado
      );
      return this.cadastroSrv.save(forms, uno, this.cadastroNo, id);
    } catch (_error: any) {
      this.log.Registrar(this.constructor.name, "save", _error.message);
    }
  }

  protected mapToForm(careRoutine: any): Array<IForm> {
    try {
      return [
        { VariavelNo: 387, ValorDado: careRoutine.residente || "" },
        { VariavelNo: 28051, ValorDado: careRoutine.prontuario || "" },
        { VariavelNo: 31928, ValorDado: careRoutine.codigo || "" },
        { VariavelNo: 31929, ValorDado: careRoutine.titulo || "" },
        {
          VariavelNo: 31930,
          ValorDado: careRoutine.habilitado ? "SIM" : "NÃO",
        },
        { VariavelNo: 31931, ValorDado: careRoutine.hospede || "" },
        { VariavelNo: 31932, ValorDado: careRoutine.hora || "" },
        { VariavelNo: 31933, ValorDado: careRoutine.diasSemana || "" },
        { VariavelNo: 31941, ValorDado: careRoutine.tipo || "" },
        { VariavelNo: 31942, ValorDado: careRoutine.descricao || "" },
        { VariavelNo: 31946, ValorDado: careRoutine.profissional || "" },
      ];
    } catch (_error: any) {
      this.log.Registrar(this.constructor.name, "mapToForm", _error.message);
    }
    return null;
  }
}
