import { clearActivities, clearActivityDetails, clearProgresses, IAppMedlogicFhirState, selectActivitiesByShift, selectActivitiesExtraByShift, selectedActivity, selectedActivityMode } from '@medlogic/fhir';
import { EnActivityMode, IShift, IActivity, AppLogService } from '@medlogic/shared/shared-interfaces';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map, Observable, tap } from 'rxjs';

@Component({
  selector: 'ml-ui-care-plan-header',
  templateUrl: './ui-care-plan-header.component.html',
  styleUrls: ['./ui-care-plan-header.component.css']
})
export class UiCarePlanHeaderComponent implements OnInit {

  @Output() menuItemClick = new EventEmitter<string>();

  activityMode$: Observable<EnActivityMode> = this.store.pipe(select(selectedActivityMode));

  scheduledActivities$: Observable<any> = this.store.pipe(
    select(selectActivitiesByShift),
    tap(act => console.log("actByShift: ", act)),
    map((selectedActivities: IShift[]) => selectedActivities.reduce((acc, cur) => [...acc, ...cur.activities], [])),
    map((activities: IActivity[]) => activities.filter(act => !act?.currentProgress?.time).length));

  extraActivities$: Observable<any> = this.store.pipe(
    select(selectActivitiesExtraByShift),
    map((selectedExtraActivities: IShift[]) => selectedExtraActivities.reduce((acc, cur) => [...acc, ...cur.activities], [])),
    map((activities: IActivity[]) => activities.length));

  selectedOption: string = EnActivityMode.CarePlan;

  ENACTIVITYMODE = EnActivityMode;

  constructor(
    private log: AppLogService,
    private store: Store<IAppMedlogicFhirState>,
  ) { }

  ngOnInit() {
  }

  onMenuItemClick(option: string): void {
    try {
      if (option === 'back') {
        this.store.dispatch(clearActivities());
        this.store.dispatch(clearActivityDetails());
        this.store.dispatch(clearProgresses());
      }
      this.selectedOption = option;
      this.menuItemClick.emit(option);
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'onMenuItemClick', error.message);
    }
  }


}
