export interface IAvaliacaoNutricional {
  ocorrenciaNo: number;
	nomePaciente:  any;
	nascimento:  Date;
	pesoAtualKgUtilizarpontoEx726:  any;
	estaturaEmMetrosUtilizarpontoEx176:  any;
	nomeMae:  any;
	circunferenciaBracoCm:  any;
	identificacao1:  any;
	identificacao2:  any;
	prontuario:  any;
	iMC:  any;
	dataAniversario:  any;
	data:  Date;
	responsavelNome:  any;
	responsavelID:  any;
	dtAdmissao:  string;
	cPF:  string;
	idade:  any;
	endereco:  string;
	telefone:  string;
	celular:  string;
	email:  string;
	circunferenciaPanturrilhaCm:  any;
	codigo:  any;
	titulo:  any;
	habilitado:  boolean;
	nomeResidente:  string;
	pai:  string;
	quarto:  string;
	usosuplementoOralLiquido:  boolean;
	descreva2:  string;
	diagnosticoNutricional:  string;
	usosuplementoOralEmPo:  boolean;
	descreva3:  string;
	usoDietaEnteral:  boolean;
	descreva4:  string;
	dietaViaOral:  boolean;
	diabetico:  boolean;
	usoespessante:  boolean;
	tipoConsistencia:  string;
	necessitaauxilio:  boolean;
	tipoauxilio:  string;
	utensiliosingestaoliquidos:  string;
	seOutrosDescreva2:  string;
	utensiliosalimentacao:  string;
	seOutrosDescreva3:  string;
	suspenderDieta:  boolean;
	motivoSuspensao:  string;
	dataInicioSuspensao:  Date;
	horaInicioSuspensao:  any;
	dataFimSuspensao:  Date;
	horaFimSuspensao:  any;
	observacoes:  string;
	adequacaoPostural:  boolean;
	descreva:  string;
	aNosUltimosTresMesesHouveDiminuicaoingestaAlimentarDevidoperdaapetiteProblemasDigestivosOuDificuldadesmastigarOuDeglutir:  string;
	eSCOREA:  any;
	eSCOREA2:  any;
	eSCOREA3:  any;
	bPerdapesoNosUltimos3Meses:  string;
	eSCOREB:  any;
	eSCOREB2:  any;
	eSCOREB3:  any;
	eSCOREB4:  any;
	cMobilidade:  string;
	eSCOREC:  any;
	eSCOREC2:  any;
	eSCOREC3:  any;
	dPassouPorAlgumEstressePsicologicoOuDoencaAgudaNosUltimosTresMeses:  string;
	eSCORED:  any;
	eProblemasNeuropsicologicos:  string;
	eSCOREE:  any;
	eSCOREE2:  any;
	eSCOREE3:  any;
	f1IndiceMassaCorporalIMCPesoKgEstaturaM:  string;
	eSCOREF1:  any;
	eSCOREF12:  any;
	eSCOREF13:  any;
	eSCOREF14:  any;
	obs:  string;
	f2CircunferenciaPanturrilhaCPEmCm:  string;
	eSCOREF2:  any;
	escoreTriagemMaximo14Pontos:  string;
	escoreTriagem:  any;
	gODoenteViveNaSuaPropriaCasaNaoEmInstituicaoGeriatricaOuHospitalar:  boolean;
	hUtilizaMaistresMedicamentosDiferentesPorDia:  boolean;
	eSCOREG:  any;
	eSCOREH:  any;
	iLesoespeleOuEscaras:  boolean;
	eSCOREI:  any;
	jQuantasRefeicoesFazPorDia:  string;
	eSCOREJ:  any;
	eSCOREJ2:  any;
	eSCOREJ3:  any;
	kOPacienteConsomePeloMenosUmaPorcaoDiarialeiteOuDerivadosQueijoIogurte:  boolean;
	kDuasOuMaisPorcoesSemanaislegumesOuOvos:  boolean;
	kCarnePeixeOuAvesTodosOsDias:  boolean;
	eSCOREK1:  any;
	eSCOREK2:  any;
	eSCOREK3:  any;
	eSCORESOMAK1K2K3:  any;
	eSCOREKTotal:  any;
	eSCOREKTotal2:  any;
	eSCOREKTotal3:  any;
	lOPacienteConsomeDuasOuMaisPorcoesDiariasfrutasOuVegetais:  boolean;
	eSCORELTotal:  any;
	mQuantosCoposliquidosAguaSucoCafeChaLeitepacienteConsomePorDia:  string;
	eSCOREM:  any;
	eSCOREM2:  any;
	eSCOREM3:  any;
	nModoseAlimentar:  string;
	eSCOREN:  any;
	eSCOREN2:  any;
	eSCOREN3:  any;
	oOPacienteAcreditaTerAlgumProblemaNutricional:  string;
	eSCOREO:  any;
	eSCOREO2:  any;
	eSCOREO3:  any;
	pEmComparacaooutrasPessoasmesmaIdadeComopacienteConsiderasuaPropriaSaude:  string;
	eSCOREP1:  any;
	eSCOREP2:  any;
	eSCOREP3:  any;
	eSCOREP4:  any;
	qCircunferenciabracoCBEmCm:  string;
	eSCOREQ:  any;
	eSCOREQ2:  any;
	eSCOREQ3:  any;
	rCircunferenciapanturrilhaCPEmCm:  string;
	eSCORER:  any;
	avaliacaoGlobalMaximo16Pontos:  any;
	pontuacaoTotal:  any;
	avaliacaoEstadoNutricional:  any;
	escoreFinalAvalicaoEstadoNutricional:  any;
	escoreFinalAvalicaoEstadoNutricional2:  any;
	iMCLegendaClassificacaoOPAS2002:  string;
	nome:  any;
	cRNA:  any;
	classificacaoIMC:  any;
	classificacaoIMC2:  any;
	classificacaoIMC3:  any;
	classificacaoIMC4:  any;
	consistencia:  string;
	tipo:  string;
	sigla:  any;
	localRefeicao:  any;
	descreva5:  string;
	conduta:  any;
	houveMudancadieta:  boolean;
	refeicaoComAdaptacao:  boolean;
	dietaDupla:  boolean;
}
