
import { refCount, publishReplay, map, filter, toArray } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { IAvaliacaoNutricional } from '@medlogic/shared/shared-interfaces';
import { Observable, of } from 'rxjs';
import { EMPTY } from 'rxjs';
import { GlobalService, LogService, IForm } from '@medlogic/shared/shared-interfaces';
import { CadastroService } from '@medlogic/shared/shared-data-access';

export abstract class AvaliacaoNutricionalService {

  // tslint:disable-next-line: max-line-length
  protected lstVariaveis = 'V_387,V_391,V_757,V_762,V_1608,V_3058,V_3328,V_3329,V_28051,V_29492,V_101175,V_101305,V_101513,V_101514,V_101570,V_101573,V_101575,V_101578,V_101579,V_101580,V_101581,V_101582,V_101684,V_101685,V_101686,V_104079,V_104082,V_104088,V_108377,V_108378,V_108384,V_108385,V_108386,V_108387,V_108388,V_108389,V_108390,V_108391,V_108392,V_108394,V_108395,V_108396,V_108397,V_108398,V_108399,V_108400,V_108401,V_108402,V_108403,V_108404,V_108405,V_108406,V_108407,V_108408,V_108409,V_108410,V_108411,V_108412,V_108413,V_108414,V_108415,V_108416,V_108417,V_108418,V_108419,V_108420,V_108421,V_108422,V_108423,V_108424,V_108425,V_108426,V_108427,V_108428,V_108429,V_108430,V_108431,V_108432,V_108433,V_108434,V_108435,V_108436,V_108437,V_108438,V_108439,V_108440,V_108441,V_108442,V_108443,V_108444,V_108445,V_108446,V_108447,V_108448,V_108449,V_108450,V_108451,V_108452,V_108453,V_108454,V_108455,V_108456,V_108457,V_108458,V_108459,V_108460,V_108461,V_108462,V_108463,V_108464,V_108465,V_108466,V_108467,V_108468,V_108469,V_108470,V_108471,V_108472,V_108473,V_108474,V_108475,V_108476,V_108477,V_108478,V_108479,V_108480,V_108481,V_108482,V_108483,V_108484,V_108485,V_108486,V_108487,V_108488,V_108489,V_108490,V_108491,V_108492,V_108493,V_108494,V_108495,V_108502,V_108503,V_108504,V_108505,V_109060,V_109169,V_109172,V_109173';
  private variavelGrid = '';
  private lstVariaveisGrid = '';

  recurrences: Array<IAvaliacaoNutricional> = new Array<IAvaliacaoNutricional>();

  cadastroNo = 24569;
  currentDtInicial = new Date();
  currentDtFinal = new Date();
  cadastrosCache: Observable<any>;


  constructor(
    protected http: HttpClient,
    protected cadastroSrv: CadastroService,
    protected glb: GlobalService,
    protected log: LogService) {
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'constructor', error.message);
    }
  }

  // TODO: Atenção: o correto não é retornar todos os dados, de todas as datas e depois filtrar, mas enviar a data como parâmetro.
  getAll(ano: number, startDate?: Date, endDate?: Date): Observable<IAvaliacaoNutricional> {
    try {
      this.cadastroNo = ano;
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      return this.getWithCache(this.cadastroNo, startDate, endDate).pipe(
        filter(f => {
          return this.glb.isBetweenIgnoreTime(f.data, startDate, endDate);
        }));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAll', error.message);
    }
    return null;
  }

  /* Método utilizado para popular uma lista com os itens ativos. */
  loadArray(ano: number): Observable<any> {
    try {
      const propLabel = 'titulo'; const propValue = 'codigo'; const propEnabled = 'habilitado';
      return this.cadastroSrv.loadArray(this.getAll(ano), propLabel, propValue, propEnabled);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'loadArray', error.message);
    }
  }

  /* Limpa o cache de forma que a próxima chamada buscará os dados do serviço novamente. */
  clearCache(): void {
    try {
      this.cadastrosCache = null;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'clearCache', error.message);
    }
  }

  protected getWithCache(cadastroNo: number, startDate: Date, endDate: Date): Observable<IAvaliacaoNutricional> {
    if (
      (startDate.getTime() !== this.currentDtInicial.getTime())
      || (endDate.getTime() !== this.currentDtFinal.getTime())
      || (!this.cadastrosCache)
    ) {
      this.currentDtInicial = startDate;
      this.currentDtFinal = endDate;
      this.cadastrosCache = this.getFromCadastro(cadastroNo, startDate, endDate);
    } else {
      console.log('retorno do cache');
    }
    return this.cadastrosCache;
  }

  protected getFromCadastro(cadastroNo: number, startDate: Date, endDate: Date): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv
        .getCadastro(cadastroNo, this.lstVariaveis).pipe(
          map(m => this.toAttribute(m)),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadatro', error.message);
    }
    return null;
  }

  /* Converte o objeto que vem do serviço para IVitalSign. */
  protected toAttribute(c: any): IAvaliacaoNutricional {
    try {
      return {
        ocorrenciaNo: c.OcorrenciaNo,
	nomePaciente: c.V_387,
	nascimento: this.glb.ddMMYYYYThhmmssToDate(c.V_391),
	pesoAtualKgUtilizarpontoEx726: c.V_757,
	estaturaEmMetrosUtilizarpontoEx176: c.V_762,
	nomeMae: c.V_1608,
	circunferenciaBracoCm: c.V_3058,
	identificacao1: c.V_3328,
	identificacao2: c.V_3329,
	prontuario: c.V_28051,
	iMC: c.V_29492,
	dataAniversario: c.V_101175,
	data: this.glb.ddMMYYYYThhmmssToDate(c.V_101305),
	responsavelNome: c.V_101513,
	responsavelID: c.V_101514,
	dtAdmissao: c.V_101570,
	cPF: c.V_101573,
	idade: c.V_101575,
	endereco: c.V_101578,
	telefone: c.V_101579,
	celular: c.V_101580,
	email: c.V_101581,
	circunferenciaPanturrilhaCm: c.V_101582,
	codigo: c.V_101684,
	titulo: c.V_101685,
	habilitado: this.glb.getBoolean(c.V_101686),
	nomeResidente: c.V_104079,
	pai: c.V_104082,
	quarto: c.V_104088,
	usosuplementoOralLiquido: this.glb.getBoolean(c.V_108377),
	descreva2: c.V_108378,
	diagnosticoNutricional: c.V_108384,
	usosuplementoOralEmPo: this.glb.getBoolean(c.V_108385),
	descreva3: c.V_108386,
	usoDietaEnteral: this.glb.getBoolean(c.V_108387),
	descreva4: c.V_108388,
	dietaViaOral: this.glb.getBoolean(c.V_108389),
	diabetico: this.glb.getBoolean(c.V_108390),
	usoespessante: this.glb.getBoolean(c.V_108391),
	tipoConsistencia: c.V_108392,
	necessitaauxilio: this.glb.getBoolean(c.V_108394),
	tipoauxilio: c.V_108395,
	utensiliosingestaoliquidos: c.V_108396,
	seOutrosDescreva: c.V_108397,
	utensiliosalimentacao: c.V_108398,
	seOutrosDescreva2: c.V_108399,
	suspenderDieta: this.glb.getBoolean(c.V_108400),
	motivoSuspensao: c.V_108401,
	dataInicioSuspensao: this.glb.ddMMYYYYThhmmssToDate(c.V_108402),
	horaInicioSuspensao: c.V_108403,
	dataFimSuspensao: this.glb.ddMMYYYYThhmmssToDate(c.V_108404),
	horaFimSuspensao: c.V_108405,
	observacoes: c.V_108406,
	adequacaoPostural: this.glb.getBoolean(c.V_108407),
	descreva: c.V_108408,
	aNosUltimosTresMesesHouveDiminuicaoingestaAlimentarDevidoperdaapetiteProblemasDigestivosOuDificuldadesmastigarOuDeglutir: c.V_108409,
	eSCOREA: c.V_108410,
	eSCOREA2: c.V_108411,
	eSCOREA3: c.V_108412,
	bPerdapesoNosUltimos3Meses: c.V_108413,
	eSCOREB: c.V_108414,
	eSCOREB2: c.V_108415,
	eSCOREB3: c.V_108416,
	eSCOREB4: c.V_108417,
	cMobilidade: c.V_108418,
	eSCOREC: c.V_108419,
	eSCOREC2: c.V_108420,
	eSCOREC3: c.V_108421,
	dPassouPorAlgumEstressePsicologicoOuDoencaAgudaNosUltimosTresMeses: c.V_108422,
	eSCORED: c.V_108423,
	eProblemasNeuropsicologicos: c.V_108424,
	eSCOREE: c.V_108425,
	eSCOREE2: c.V_108426,
	eSCOREE3: c.V_108427,
	f1IndiceMassaCorporalIMCPesoKgEstaturaM: c.V_108428,
	eSCOREF1: c.V_108429,
	eSCOREF12: c.V_108430,
	eSCOREF13: c.V_108431,
	eSCOREF14: c.V_108432,
	obs: c.V_108433,
	f2CircunferenciaPanturrilhaCPEmCm: c.V_108434,
	eSCOREF2: c.V_108435,
	escoreTriagemMaximo14Pontos: c.V_108436,
	escoreTriagem: c.V_108437,
	gODoenteViveNaSuaPropriaCasaNaoEmInstituicaoGeriatricaOuHospitalar: this.glb.getBoolean(c.V_108438),
	hUtilizaMaistresMedicamentosDiferentesPorDia: this.glb.getBoolean(c.V_108439),
	eSCOREG: c.V_108440,
	eSCOREH: c.V_108441,
	iLesoespeleOuEscaras: this.glb.getBoolean(c.V_108442),
	eSCOREI: c.V_108443,
	jQuantasRefeicoesFazPorDia: c.V_108444,
	eSCOREJ: c.V_108445,
	eSCOREJ2: c.V_108446,
	eSCOREJ3: c.V_108447,
	kOPacienteConsomePeloMenosUmaPorcaoDiarialeiteOuDerivadosQueijoIogurte: this.glb.getBoolean(c.V_108448),
	kDuasOuMaisPorcoesSemanaislegumesOuOvos: this.glb.getBoolean(c.V_108449),
	kCarnePeixeOuAvesTodosOsDias: this.glb.getBoolean(c.V_108450),
	eSCOREK1: c.V_108451,
	eSCOREK2: c.V_108452,
	eSCOREK3: c.V_108453,
	eSCORESOMAK1K2K3: c.V_108454,
	eSCOREKTotal: c.V_108455,
	eSCOREKTotal2: c.V_108456,
	eSCOREKTotal3: c.V_108457,
	lOPacienteConsomeDuasOuMaisPorcoesDiariasfrutasOuVegetais: this.glb.getBoolean(c.V_108458),
	eSCORELTotal: c.V_108459,
	mQuantosCoposliquidosAguaSucoCafeChaLeitepacienteConsomePorDia: c.V_108460,
	eSCOREM: c.V_108461,
	eSCOREM2: c.V_108462,
	eSCOREM3: c.V_108463,
	nModoseAlimentar: c.V_108464,
	eSCOREN: c.V_108465,
	eSCOREN2: c.V_108466,
	eSCOREN3: c.V_108467,
	oOPacienteAcreditaTerAlgumProblemaNutricional: c.V_108468,
	eSCOREO: c.V_108469,
	eSCOREO2: c.V_108470,
	eSCOREO3: c.V_108471,
	pEmComparacaooutrasPessoasmesmaIdadeComopacienteConsiderasuaPropriaSaude: c.V_108472,
	eSCOREP: c.V_108473,
	eSCOREP2: c.V_108474,
	eSCOREP3: c.V_108475,
	eSCOREP4: c.V_108476,
	qCircunferenciabracoCBEmCm: c.V_108477,
	eSCOREQ: c.V_108478,
	eSCOREQ2: c.V_108479,
	eSCOREQ3: c.V_108480,
	rCircunferenciapanturrilhaCPEmCm: c.V_108481,
	eSCORER: c.V_108482,
	avaliacaoGlobalMaximo16Pontos: c.V_108483,
	pontuacaoTotal: c.V_108484,
	avaliacaoEstadoNutricional: c.V_108485,
	escoreFinalAvalicaoEstadoNutricional: c.V_108486,
	escoreFinalAvalicaoEstadoNutricional2: c.V_108487,
	iMCLegendaClassificacaoOPAS2002: c.V_108488,
	nome: c.V_108489,
	cRNA: c.V_108490,
	classificacaoIMC: c.V_108491,
	classificacaoIMC2: c.V_108492,
	classificacaoIMC3: c.V_108493,
	classificacaoIMC4: c.V_108494,
	consistencia: c.V_108495,
	tipo: c.V_108502,
	sigla: c.V_108503,
	localRefeicao: c.V_108504,
	descreva5: c.V_108505,
	conduta: c.V_109060,
	houveMudancadieta: this.glb.getBoolean(c.V_109169),
	refeicaoComAdaptacao: this.glb.getBoolean(c.V_109172),
	dietaDupla: this.glb.getBoolean(c.V_109173),

     } as any as IAvaliacaoNutricional;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'toAttribute', error.message);
    }
    return null;
  }

  /* Insere ou atualiza o item.
    * Se for atualização, especificar o id. Caso contrário, não fornecê-lo.
    */
  save(movimentacao: IAvaliacaoNutricional, uno: number, id?: number): Observable<any> {
    try {
      const forms: IForm[] = this.mapToForm(movimentacao).filter(f => f.ValorDado);
      return this.cadastroSrv.save(forms, uno, this.cadastroNo, id);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'save', error.message);
    }
    return EMPTY;
  }

  protected mapToForm(avaliacaoNutricional: IAvaliacaoNutricional): Array<IForm> {
    try {
        return [
          { VariavelNo: 387, ValorDado: avaliacaoNutricional.nomePaciente || '' },
          { VariavelNo: 391, ValorDado: this.glb.ddMMYYYYThhmmssToDate(avaliacaoNutricional.nascimento) as any },
          { VariavelNo: 757, ValorDado: avaliacaoNutricional.pesoAtualKgUtilizarpontoEx726 || '' },
          { VariavelNo: 762, ValorDado: avaliacaoNutricional.estaturaEmMetrosUtilizarpontoEx176 || '' },
          { VariavelNo: 1608, ValorDado: avaliacaoNutricional.nomeMae || '' },
          { VariavelNo: 3058, ValorDado: avaliacaoNutricional.circunferenciaBracoCm || '' },
          { VariavelNo: 3328, ValorDado: avaliacaoNutricional.identificacao1 || '' },
          { VariavelNo: 3329, ValorDado: avaliacaoNutricional.identificacao2 || '' },
          { VariavelNo: 28051, ValorDado: avaliacaoNutricional.prontuario || '' },
          { VariavelNo: 29492, ValorDado: avaliacaoNutricional.iMC || '' },
          { VariavelNo: 101175, ValorDado: avaliacaoNutricional.dataAniversario || '' },
          { VariavelNo: 101305, ValorDado: this.glb.ddMMYYYYThhmmssToDate(avaliacaoNutricional.data) as any },
          { VariavelNo: 101513, ValorDado: avaliacaoNutricional.responsavelNome || '' },
          { VariavelNo: 101514, ValorDado: avaliacaoNutricional.responsavelID || '' },
          { VariavelNo: 101570, ValorDado: avaliacaoNutricional.dtAdmissao || '' },
          { VariavelNo: 101573, ValorDado: avaliacaoNutricional.cPF || '' },
          { VariavelNo: 101575, ValorDado: avaliacaoNutricional.idade || '' },
          { VariavelNo: 101578, ValorDado: avaliacaoNutricional.endereco || '' },
          { VariavelNo: 101579, ValorDado: avaliacaoNutricional.telefone || '' },
          { VariavelNo: 101580, ValorDado: avaliacaoNutricional.celular || '' },
          { VariavelNo: 101581, ValorDado: avaliacaoNutricional.email || '' },
          { VariavelNo: 101582, ValorDado: avaliacaoNutricional.circunferenciaPanturrilhaCm || '' },
          { VariavelNo: 101684, ValorDado: avaliacaoNutricional.codigo || '' },
          { VariavelNo: 101685, ValorDado: avaliacaoNutricional.titulo || '' },
          { VariavelNo: 101686, ValorDado: avaliacaoNutricional.habilitado ? 'SIM' : 'NÃO' },
          { VariavelNo: 104079, ValorDado: avaliacaoNutricional.nomeResidente || '' },
          { VariavelNo: 104082, ValorDado: avaliacaoNutricional.pai || '' },
          { VariavelNo: 104088, ValorDado: avaliacaoNutricional.quarto || '' },
          { VariavelNo: 108377, ValorDado: avaliacaoNutricional.usosuplementoOralLiquido ? 'SIM' : 'NÃO' },
          { VariavelNo: 108378, ValorDado: avaliacaoNutricional.descreva || '' },
          { VariavelNo: 108384, ValorDado: avaliacaoNutricional.diagnosticoNutricional || '' },
          { VariavelNo: 108385, ValorDado: avaliacaoNutricional.usosuplementoOralEmPo ? 'SIM' : 'NÃO' },
          { VariavelNo: 108386, ValorDado: avaliacaoNutricional.descreva || '' },
          { VariavelNo: 108387, ValorDado: avaliacaoNutricional.usoDietaEnteral ? 'SIM' : 'NÃO' },
          { VariavelNo: 108388, ValorDado: avaliacaoNutricional.descreva || '' },
          { VariavelNo: 108389, ValorDado: avaliacaoNutricional.dietaViaOral ? 'SIM' : 'NÃO' },
          { VariavelNo: 108390, ValorDado: avaliacaoNutricional.diabetico ? 'SIM' : 'NÃO' },
          { VariavelNo: 108391, ValorDado: avaliacaoNutricional.usoespessante ? 'SIM' : 'NÃO' },
          { VariavelNo: 108392, ValorDado: avaliacaoNutricional.tipoConsistencia || '' },
          { VariavelNo: 108394, ValorDado: avaliacaoNutricional.necessitaauxilio ? 'SIM' : 'NÃO' },
          { VariavelNo: 108395, ValorDado: avaliacaoNutricional.tipoauxilio || '' },
          { VariavelNo: 108396, ValorDado: avaliacaoNutricional.utensiliosingestaoliquidos || '' },
          { VariavelNo: 108397, ValorDado: avaliacaoNutricional.seOutrosDescreva2 || '' },
          { VariavelNo: 108398, ValorDado: avaliacaoNutricional.utensiliosalimentacao || '' },
          { VariavelNo: 108399, ValorDado: avaliacaoNutricional.seOutrosDescreva3 || '' },
          { VariavelNo: 108400, ValorDado: avaliacaoNutricional.suspenderDieta ? 'SIM' : 'NÃO' },
          { VariavelNo: 108401, ValorDado: avaliacaoNutricional.motivoSuspensao || '' },
          { VariavelNo: 108402, ValorDado: this.glb.ddMMYYYYThhmmssToDate(avaliacaoNutricional.dataInicioSuspensao) as any },
          { VariavelNo: 108403, ValorDado: avaliacaoNutricional.horaInicioSuspensao || '' },
          { VariavelNo: 108404, ValorDado: this.glb.ddMMYYYYThhmmssToDate(avaliacaoNutricional.dataFimSuspensao) as any },
          { VariavelNo: 108405, ValorDado: avaliacaoNutricional.horaFimSuspensao || '' },
          { VariavelNo: 108406, ValorDado: avaliacaoNutricional.observacoes || '' },
          { VariavelNo: 108407, ValorDado: avaliacaoNutricional.adequacaoPostural ? 'SIM' : 'NÃO' },
          { VariavelNo: 108408, ValorDado: avaliacaoNutricional.descreva || '' },
          { VariavelNo: 108409, ValorDado: avaliacaoNutricional.aNosUltimosTresMesesHouveDiminuicaoingestaAlimentarDevidoperdaapetiteProblemasDigestivosOuDificuldadesmastigarOuDeglutir || '' },
          { VariavelNo: 108410, ValorDado: avaliacaoNutricional.eSCOREA || '' },
          { VariavelNo: 108411, ValorDado: avaliacaoNutricional.eSCOREA2 || '' },
          { VariavelNo: 108412, ValorDado: avaliacaoNutricional.eSCOREA3 || '' },
          { VariavelNo: 108413, ValorDado: avaliacaoNutricional.bPerdapesoNosUltimos3Meses || '' },
          { VariavelNo: 108414, ValorDado: avaliacaoNutricional.eSCOREB || '' },
          { VariavelNo: 108415, ValorDado: avaliacaoNutricional.eSCOREB2 || '' },
          { VariavelNo: 108416, ValorDado: avaliacaoNutricional.eSCOREB3 || '' },
          { VariavelNo: 108417, ValorDado: avaliacaoNutricional.eSCOREB4 || '' },
          { VariavelNo: 108418, ValorDado: avaliacaoNutricional.cMobilidade || '' },
          { VariavelNo: 108419, ValorDado: avaliacaoNutricional.eSCOREC || '' },
          { VariavelNo: 108420, ValorDado: avaliacaoNutricional.eSCOREC2 || '' },
          { VariavelNo: 108421, ValorDado: avaliacaoNutricional.eSCOREC3 || '' },
          { VariavelNo: 108422, ValorDado: avaliacaoNutricional.dPassouPorAlgumEstressePsicologicoOuDoencaAgudaNosUltimosTresMeses || '' },
          { VariavelNo: 108423, ValorDado: avaliacaoNutricional.eSCORED || '' },
          { VariavelNo: 108424, ValorDado: avaliacaoNutricional.eProblemasNeuropsicologicos || '' },
          { VariavelNo: 108425, ValorDado: avaliacaoNutricional.eSCOREE || '' },
          { VariavelNo: 108426, ValorDado: avaliacaoNutricional.eSCOREE2 || '' },
          { VariavelNo: 108427, ValorDado: avaliacaoNutricional.eSCOREE3 || '' },
          { VariavelNo: 108428, ValorDado: avaliacaoNutricional.f1IndiceMassaCorporalIMCPesoKgEstaturaM || '' },
          { VariavelNo: 108429, ValorDado: avaliacaoNutricional.eSCOREF1 || '' },
          { VariavelNo: 108430, ValorDado: avaliacaoNutricional.eSCOREF12 || '' },
          { VariavelNo: 108431, ValorDado: avaliacaoNutricional.eSCOREF13 || '' },
          { VariavelNo: 108432, ValorDado: avaliacaoNutricional.eSCOREF14 || '' },
          { VariavelNo: 108433, ValorDado: avaliacaoNutricional.obs || '' },
          { VariavelNo: 108434, ValorDado: avaliacaoNutricional.f2CircunferenciaPanturrilhaCPEmCm || '' },
          { VariavelNo: 108435, ValorDado: avaliacaoNutricional.eSCOREF2 || '' },
          { VariavelNo: 108436, ValorDado: avaliacaoNutricional.escoreTriagemMaximo14Pontos || '' },
          { VariavelNo: 108437, ValorDado: avaliacaoNutricional.escoreTriagem || '' },
          { VariavelNo: 108438, ValorDado: avaliacaoNutricional.gODoenteViveNaSuaPropriaCasaNaoEmInstituicaoGeriatricaOuHospitalar ? 'SIM' : 'NÃO' },
          { VariavelNo: 108439, ValorDado: avaliacaoNutricional.hUtilizaMaistresMedicamentosDiferentesPorDia ? 'SIM' : 'NÃO' },
          { VariavelNo: 108440, ValorDado: avaliacaoNutricional.eSCOREG || '' },
          { VariavelNo: 108441, ValorDado: avaliacaoNutricional.eSCOREH || '' },
          { VariavelNo: 108442, ValorDado: avaliacaoNutricional.iLesoespeleOuEscaras ? 'SIM' : 'NÃO' },
          { VariavelNo: 108443, ValorDado: avaliacaoNutricional.eSCOREI || '' },
          { VariavelNo: 108444, ValorDado: avaliacaoNutricional.jQuantasRefeicoesFazPorDia || '' },
          { VariavelNo: 108445, ValorDado: avaliacaoNutricional.eSCOREJ || '' },
          { VariavelNo: 108446, ValorDado: avaliacaoNutricional.eSCOREJ2 || '' },
          { VariavelNo: 108447, ValorDado: avaliacaoNutricional.eSCOREJ3 || '' },
          { VariavelNo: 108448, ValorDado: avaliacaoNutricional.kOPacienteConsomePeloMenosUmaPorcaoDiarialeiteOuDerivadosQueijoIogurte ? 'SIM' : 'NÃO' },
          { VariavelNo: 108449, ValorDado: avaliacaoNutricional.kDuasOuMaisPorcoesSemanaislegumesOuOvos ? 'SIM' : 'NÃO' },
          { VariavelNo: 108450, ValorDado: avaliacaoNutricional.kCarnePeixeOuAvesTodosOsDias ? 'SIM' : 'NÃO' },
          { VariavelNo: 108451, ValorDado: avaliacaoNutricional.eSCOREK1 || '' },
          { VariavelNo: 108452, ValorDado: avaliacaoNutricional.eSCOREK2 || '' },
          { VariavelNo: 108453, ValorDado: avaliacaoNutricional.eSCOREK3 || '' },
          { VariavelNo: 108454, ValorDado: avaliacaoNutricional.eSCORESOMAK1K2K3 || '' },
          { VariavelNo: 108455, ValorDado: avaliacaoNutricional.eSCOREKTotal || '' },
          { VariavelNo: 108456, ValorDado: avaliacaoNutricional.eSCOREKTotal2 || '' },
          { VariavelNo: 108457, ValorDado: avaliacaoNutricional.eSCOREKTotal3 || '' },
          { VariavelNo: 108458, ValorDado: avaliacaoNutricional.lOPacienteConsomeDuasOuMaisPorcoesDiariasfrutasOuVegetais ? 'SIM' : 'NÃO' },
          { VariavelNo: 108459, ValorDado: avaliacaoNutricional.eSCORELTotal || '' },
          { VariavelNo: 108460, ValorDado: avaliacaoNutricional.mQuantosCoposliquidosAguaSucoCafeChaLeitepacienteConsomePorDia || '' },
          { VariavelNo: 108461, ValorDado: avaliacaoNutricional.eSCOREM || '' },
          { VariavelNo: 108462, ValorDado: avaliacaoNutricional.eSCOREM2 || '' },
          { VariavelNo: 108463, ValorDado: avaliacaoNutricional.eSCOREM3 || '' },
          { VariavelNo: 108464, ValorDado: avaliacaoNutricional.nModoseAlimentar || '' },
          { VariavelNo: 108465, ValorDado: avaliacaoNutricional.eSCOREN || '' },
          { VariavelNo: 108466, ValorDado: avaliacaoNutricional.eSCOREN2 || '' },
          { VariavelNo: 108467, ValorDado: avaliacaoNutricional.eSCOREN3 || '' },
          { VariavelNo: 108468, ValorDado: avaliacaoNutricional.oOPacienteAcreditaTerAlgumProblemaNutricional || '' },
          { VariavelNo: 108469, ValorDado: avaliacaoNutricional.eSCOREO || '' },
          { VariavelNo: 108470, ValorDado: avaliacaoNutricional.eSCOREO2 || '' },
          { VariavelNo: 108471, ValorDado: avaliacaoNutricional.eSCOREO3 || '' },
          { VariavelNo: 108472, ValorDado: avaliacaoNutricional.pEmComparacaooutrasPessoasmesmaIdadeComopacienteConsiderasuaPropriaSaude || '' },
          { VariavelNo: 108473, ValorDado: avaliacaoNutricional.eSCOREP1 || '' },
          { VariavelNo: 108474, ValorDado: avaliacaoNutricional.eSCOREP2 || '' },
          { VariavelNo: 108475, ValorDado: avaliacaoNutricional.eSCOREP3 || '' },
          { VariavelNo: 108476, ValorDado: avaliacaoNutricional.eSCOREP4|| '' },
          { VariavelNo: 108477, ValorDado: avaliacaoNutricional.qCircunferenciabracoCBEmCm || '' },
          { VariavelNo: 108478, ValorDado: avaliacaoNutricional.eSCOREQ || '' },
          { VariavelNo: 108479, ValorDado: avaliacaoNutricional.eSCOREQ2 || '' },
          { VariavelNo: 108480, ValorDado: avaliacaoNutricional.eSCOREQ3 || '' },
          { VariavelNo: 108481, ValorDado: avaliacaoNutricional.rCircunferenciapanturrilhaCPEmCm || '' },
          { VariavelNo: 108482, ValorDado: avaliacaoNutricional.eSCORER || '' },
          { VariavelNo: 108483, ValorDado: avaliacaoNutricional.avaliacaoGlobalMaximo16Pontos || '' },
          { VariavelNo: 108484, ValorDado: avaliacaoNutricional.pontuacaoTotal || '' },
          { VariavelNo: 108485, ValorDado: avaliacaoNutricional.avaliacaoEstadoNutricional || '' },
          { VariavelNo: 108486, ValorDado: avaliacaoNutricional.escoreFinalAvalicaoEstadoNutricional || '' },
          { VariavelNo: 108487, ValorDado: avaliacaoNutricional.escoreFinalAvalicaoEstadoNutricional2 || '' },
          { VariavelNo: 108488, ValorDado: avaliacaoNutricional.iMCLegendaClassificacaoOPAS2002 || '' },
          { VariavelNo: 108489, ValorDado: avaliacaoNutricional.nome || '' },
          { VariavelNo: 108490, ValorDado: avaliacaoNutricional.cRNA || '' },
          { VariavelNo: 108491, ValorDado: avaliacaoNutricional.classificacaoIMC || '' },
          { VariavelNo: 108492, ValorDado: avaliacaoNutricional.classificacaoIMC2 || '' },
          { VariavelNo: 108493, ValorDado: avaliacaoNutricional.classificacaoIMC3 || '' },
          { VariavelNo: 108494, ValorDado: avaliacaoNutricional.classificacaoIMC4 || '' },
          { VariavelNo: 108495, ValorDado: avaliacaoNutricional.consistencia || '' },
          { VariavelNo: 108502, ValorDado: avaliacaoNutricional.tipo || '' },
          { VariavelNo: 108503, ValorDado: avaliacaoNutricional.sigla || '' },
          { VariavelNo: 108504, ValorDado: avaliacaoNutricional.localRefeicao || '' },
          { VariavelNo: 108505, ValorDado: avaliacaoNutricional.descreva || '' },
          { VariavelNo: 109060, ValorDado: avaliacaoNutricional.conduta || '' },
          { VariavelNo: 109169, ValorDado: avaliacaoNutricional.houveMudancadieta ? 'SIM' : 'NÃO' },
          { VariavelNo: 109172, ValorDado: avaliacaoNutricional.refeicaoComAdaptacao ? 'SIM' : 'NÃO' },
          { VariavelNo: 109173, ValorDado: avaliacaoNutricional.dietaDupla ? 'SIM' : 'NÃO' },
              ];
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapToForm', error.message);
    }
    return null;
  }

}
