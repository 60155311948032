import { LibService } from '@medlogic/shared/geform';
import { map, publishReplay, refCount } from 'rxjs/operators';
import { IPatient } from '@medlogic/shared/shared-interfaces';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { GlobalService, LogService, IForm, ConfigJsonService } from '@medlogic/shared/shared-interfaces';
import { CadastroService } from '@medlogic/shared/shared-data-access';


export abstract class PatientService {

  // tslint:disable: max-line-length
  private lstVariaveis = 'V_109167,V_109168,V_387,V_391,V_1547,V_1608,V_2230,V_2231,V_2232,V_2261,V_2262,V_2263,V_2265,V_2267,V_2268,V_2269,V_2270,V_2271,V_2273,V_2274,V_2275,V_2276,V_2277,V_2278,V_2279,V_2280,V_2282,V_2283,V_2285,V_2286,V_2287,V_2418,V_2498,V_2548,V_2592,V_3230,V_3280,V_3328,V_3329,V_3330,V_3481,V_3482,V_3483,V_3487,V_3488,V_3491,V_3492,V_3911,V_28065,V_28660,V_28661,V_28662,V_28755,V_28756,V_28757,V_28758,V_28759,V_28760,V_28761,V_28762,V_28763,V_28764,V_28765,V_28766,V_29006,V_29007,V_29008,V_29009,V_29010,V_29011,V_29012,V_29013,V_29065,V_29197,V_29293,V_29294,V_3304,V_29792,V_29793,V_29818,V_1259,V_757,V_762,V_764,V_28051';
  private variavelGrid = '';
  private lstVariaveisGrid = '';

  recurrences: IPatient[] = new Array<IPatient>();

  cadastroNo = -1; // 2243;
  currentDtInicial: Date = new Date();
  currentDtFinal: Date = new Date();
  cadastrosCache: Observable<any>;

  constructor(
    protected cadastroSrv: CadastroService,
    protected glb: GlobalService,
    protected cnfJson: ConfigJsonService,
    protected log: LogService,
    protected lib: LibService,
  ) { }

  getAll(cadastroNo: number, startDate: Date = null, endDate: Date = null): Observable<IPatient> {
    try {
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      this.cadastroNo = cadastroNo;
      return this.getWithCache(this.cadastroNo, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAll', error.message);
    }
    return of(null);
  }

  /* Limpa o cache de forma que a próxima chamada buscará os dados do serviço novamente. */
  clearCache(): void {
    try {
      this.cadastrosCache = null;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'clearCache', error.message);
    }
  }

  protected getWithCache(cadastroNo: number, startDate: Date, endDate: Date): Observable<IPatient> {
    if (
      !this.glb.isEqualIgnoreTime(startDate, this.currentDtInicial) ||
      !this.glb.isEqualIgnoreTime(endDate, this.currentDtFinal) ||
      !this.cadastrosCache
    ) {
      this.currentDtInicial = startDate;
      this.currentDtFinal = endDate;
      this.cadastrosCache = this.getFromCadastro(cadastroNo, startDate, endDate);
    } else {
      // console.log('retorno do cache');
    }
    return this.cadastrosCache;
  }

  protected getFromCadastro(cadastroNo: number, startDate: Date, endDate: Date): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv.getCadastro(cadastroNo, this.lstVariaveis).pipe(
        map(c => this.toAttribute(c)),
        publishReplay(),
        refCount()
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadatro', error.message);
    }
    return null;
  }

  /* Mapeia para o objeto principal. */
  protected toAttribute(c: any): IPatient {
    try {
      const patient = {
        nomeHospede: c.V_387,
        nascimento: this.glb.ddMMYYYYThhmmssToDate(c.V_391),
        outraReligiosidade: c.V_1547,
        nomemae: c.V_1608,
        codigo: c.V_2230,
        titulo: c.V_2231,
        habilitado: this.lib.getBoolean(c.V_2232),
        sexo: c.V_2261,
        estadoCivil: c.V_2262,
        estado: c.V_2263,
        cor: c.V_2265,
        aposentado: this.lib.getBoolean(c.V_2267),
        religiao: c.V_2268,
        data: this.glb.ddMMYYYYThhmmssToDate(c.V_2269),
        numeroCMCCentralMarcacaoConsulta: c.V_2270,
        cNS: c.V_2271,
        profissao: c.V_2273,
        idade: c.V_2274,
        naturalidade: c.V_2275,
        paisNascimento: c.V_2276,
        nomeSocial: c.V_2277,
        cPF: c.V_2278,
        rG: c.V_2279,
        anosescolaridade: c.V_2280,
        enfermeiroReferenciaUBS: c.V_2282,
        medicoReferencia: c.V_2283,
        centroSaude: c.V_2285,
        regional: c.V_2286,
        telefone: c.V_2287,
        planoSaude: c.V_2418,
        sistemasaudequeEstaVinculado: this.lib.getBoolean(c.V_2498),
        iDInicio: c.V_2548,
        sistemasaudeVinculado: c.V_2592,
        descricao: c.V_3230,
        classificacao: c.V_3280,
        identificacao1: c.V_3328,
        identificacao2: c.V_3329,
        sAME: c.V_3330,
        especialidade: c.V_3481,
        fisioterapeuta: c.V_3482,
        psicologo: c.V_3483,
        temAlgumaAlergiamaiorGravidade: this.lib.getBoolean(c.V_3487),
        seSimQual: c.V_3488,
        temAlgumaDeficienciaVisualAuditivaFisicaOuOutra: this.lib.getBoolean(c.V_3491),
        seSimQual2: c.V_3492,
        nomepai: c.V_3911,
        situacaoPaciente: this.lib.getBoolean(c.V_28065, 'Ativo'),
        pacienteFalecido: this.lib.getBoolean(c.V_28660),
        dataObito: this.glb.ddMMYYYYThhmmssToDate(c.V_28661),
        anoObito: c.V_28662,
        usaFralda: this.lib.getBoolean(c.V_28755),
        comeSozinho: this.lib.getBoolean(c.V_28756),
        cadeirante: this.lib.getBoolean(c.V_28757),
        traqueostomizado: this.lib.getBoolean(c.V_28758),
        ulceradecubito: this.lib.getBoolean(c.V_28759),
        sondaGastrica: this.lib.getBoolean(c.V_28760),
        sondaNaso: this.lib.getBoolean(c.V_28761),
        comUlceraPosHospitalizacao: this.lib.getBoolean(c.V_28762),
        hospital: c.V_28763,
        fraturaColofemur: c.V_28764,
        diabetico: this.lib.getBoolean(c.V_28765),
        usaInsulina: this.lib.getBoolean(c.V_28766),
        menores60: c.V_28767,
        dataAdmissao: this.glb.ddMMYYYYThhmmssToDate(c.V_28768),
        anoAdmissao: c.V_28769,
        admissaoAntes2000: c.V_28770,
        hospitalSim: c.V_28774,
        agendamento: c.V_28868,
        unidade: c.V_28953,
        urlLogo: c.V_28954,
        prontuarioSoma: c.V_28958,
        filtro: c.V_28959,
        totalCadastro: c.V_28960,
        prontuarioFinalCalc: c.V_28961,
        pRTO: c.V_28970,
        identificacoes: c.V_28982,
        endereco: c.V_29006,
        numero: c.V_29007,
        bairro: c.V_29008,
        cidade: c.V_29009,
        telefone2: c.V_29010,
        estado2: c.V_29011,
        celular: c.V_29012,
        eMail: c.V_29013,
        quarto: c.V_29065,
        regiao: c.V_29197,
        trato: c.V_29293,
        educacao: c.V_29294,
        foto: this.cnfJson.replaceImgPath(c.V_3304),
        referencia: c.V_29792,
        perfilCliente: c.V_29793,
        informacoesImportante: c.V_29818,
        diagnostico: c.V_1259,
        pesoAtual: c.V_757,
        altura: c.V_762,
        imc: c.V_764,
        prontuario: c.V_28051,
        lilas: this.lib.getBoolean(c.V_109167),
        obito: this.lib.getBoolean(c.V_109168),
        calcCodigo: this.calcPatientCodigo
      } as IPatient;
      // É o código calculado. Há inconsistência na codificação, hora no prontuário, hora no código, hora apenas na identificacao2 esse campo calculado visa corrigir isso.
      return patient;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'toAttribute', error.message);
    }
    return null;
  }

  /* É o código calculado. Há inconsistência na codificação, hora no prontuário,
   * hora no código, hora apenas na identificacao2 esse campo calculado visa corrigir isso.
   * Esse método é exigido pela interface IPatient.
   * Para usar: patient.calcCodigo(this)
  */
  calcPatientCodigo = (thisPatient: IPatient): number => {
    try {
      const value = this.isValid(thisPatient.prontuario) ? thisPatient.prontuario : (this.isValid(thisPatient.codigo) ? thisPatient.codigo : this.extractCode(thisPatient.identificacao2));
      return this.glb.getTypedValue(value).value;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'calcCodigo', error.message);
    }
    return null;
  }

  /* Define se o valor é diferente de nulo, é numérico e diferente de -1. */
  protected isValid(value: any): boolean {
    try {
      return !this.glb.isNullOrEmpty(value) && value.toString() !== '-1' && this.glb.isNumeric(value);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isValid', error.message);
    }
    return false;
  }

  /* Extrai o código da identificação 2. */
  protected extractCode(identificacao2: string): number {
    try {
      const split = identificacao2.indexOf('__') >= 0 ? identificacao2.split('__') : identificacao2.split('_');
      if (split && split.length > 1) {
        return +split[1];
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'extractCode', error.message);
    }
    return -1;
  }

  protected getFromCadastroFiltro(
    cadastroNo: number,
    filtro: string,
    startDate: Date,
    endDate: Date
  ): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      // console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv.getCadastroComFiltro(cadastroNo, this.lstVariaveis, filtro, true).pipe(
        map(c => this.toAttribute(c)),
        publishReplay(),
        refCount()
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadatro', error.message);
    }
    return null;
  }

  /* Retorna dados filtrando a query no bd. strFilter é do tipo: `V_2230:${patientId}` */
  protected getFiltered(cadastroNo: number, strFilter: string, startDate: Date, endDate: Date): Observable<IPatient> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      return this.cadastroSrv
        .getCadastroComFiltro(cadastroNo, this.lstVariaveis, strFilter, true)
        .pipe(
          map(c => this.toAttribute(c)),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFiltered', error.message);
    }
    return null;
  }

  protected getWithCacheFilter(
    cadastroNo: number,
    filtro: string,
    startDate: Date,
    endDate: Date
  ): Observable<IPatient> {
    if (
      startDate.getTime() !== this.currentDtInicial.getTime() ||
      endDate.getTime() !== this.currentDtFinal.getTime() ||
      !this.cadastrosCache
    ) {
      this.currentDtInicial = startDate;
      this.currentDtFinal = endDate;
      this.cadastrosCache = this.getFromCadastroFiltro(cadastroNo, filtro, startDate, endDate);
    } else {
      // console.log('retorno do cache');
    }
    return this.cadastrosCache;
  }

  /* Insere ou atualiza o item.
   * Se for atualização, especificar o id. Caso contrário, não fornecê-lo.
   */
  save(patient: IPatient, uno: number, id?: number): Observable<any> {
    try {
      const forms: IForm[] = this.mapToForm(patient).filter((f) => f.ValorDado);
      return this.cadastroSrv.save(forms, uno, this.cadastroNo, id);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'save', error.message);
    }
  }

  protected mapToForm(patient: IPatient): IForm[] {
    try {
      return [
        { VariavelNo: 387, ValorDado: patient.nomeHospede || '' },
        { VariavelNo: 391, ValorDado: this.glb.ddMMYYYYThhmmssToDate(patient.nascimento) },
        { VariavelNo: 1547, ValorDado: patient.outraReligiosidade || '' },
        { VariavelNo: 1608, ValorDado: patient.nomemae || '' },
        { VariavelNo: 2230, ValorDado: patient.codigo || '' },
        { VariavelNo: 2231, ValorDado: patient.titulo || '' },
        { VariavelNo: 2232, ValorDado: patient.habilitado ? 'SIM' : 'NÃO' },
        { VariavelNo: 2261, ValorDado: patient.sexo || '' },
        { VariavelNo: 2262, ValorDado: patient.estadoCivil || '' },
        { VariavelNo: 2263, ValorDado: patient.estado || '' },
        { VariavelNo: 2265, ValorDado: patient.cor || '' },
        { VariavelNo: 2267, ValorDado: patient.aposentado ? 'SIM' : 'NÃO' },
        { VariavelNo: 2268, ValorDado: patient.religiao || '' },
        { VariavelNo: 2269, ValorDado: this.glb.ddMMYYYYThhmmssToDate(patient.data) },
        { VariavelNo: 2270, ValorDado: patient.numeroCMCCentralMarcacaoConsulta || '' },
        { VariavelNo: 2271, ValorDado: patient.cNS || '' },
        { VariavelNo: 2273, ValorDado: patient.profissao || '' },
        { VariavelNo: 2274, ValorDado: patient.idade || '' },
        { VariavelNo: 2275, ValorDado: patient.naturalidade || '' },
        { VariavelNo: 2276, ValorDado: patient.paisNascimento || '' },
        { VariavelNo: 2277, ValorDado: patient.nomeSocial || '' },
        { VariavelNo: 2278, ValorDado: patient.cPF || '' },
        { VariavelNo: 2279, ValorDado: patient.rG || '' },
        { VariavelNo: 2280, ValorDado: patient.anosescolaridade || '' },
        { VariavelNo: 2282, ValorDado: patient.enfermeiroReferenciaUBS || '' },
        { VariavelNo: 2283, ValorDado: patient.medicoReferencia || '' },
        { VariavelNo: 2285, ValorDado: patient.centroSaude || '' },
        { VariavelNo: 2286, ValorDado: patient.regional || '' },
        { VariavelNo: 2287, ValorDado: patient.telefone || '' },
        { VariavelNo: 2418, ValorDado: patient.planoSaude || '' },
        { VariavelNo: 2498, ValorDado: patient.sistemasaudequeEstaVinculado ? 'SIM' : 'NÃO' },
        { VariavelNo: 2548, ValorDado: patient.iDInicio || '' },
        { VariavelNo: 2592, ValorDado: patient.sistemasaudeVinculado || '' },
        { VariavelNo: 3230, ValorDado: patient.descricao || '' },
        { VariavelNo: 3280, ValorDado: patient.classificacao || '' },
        { VariavelNo: 3328, ValorDado: patient.identificacao1 || '' },
        { VariavelNo: 3329, ValorDado: patient.identificacao2 || '' },
        { VariavelNo: 3330, ValorDado: patient.sAME || '' },
        { VariavelNo: 3481, ValorDado: patient.especialidade || '' },
        { VariavelNo: 3482, ValorDado: patient.fisioterapeuta || '' },
        { VariavelNo: 3483, ValorDado: patient.psicologo || '' },
        { VariavelNo: 3487, ValorDado: patient.temAlgumaAlergiamaiorGravidade ? 'SIM' : 'NÃO' },
        { VariavelNo: 3488, ValorDado: patient.seSimQual || '' },
        {
          VariavelNo: 3491,
          ValorDado: patient.temAlgumaDeficienciaVisualAuditivaFisicaOuOutra ? 'SIM' : 'NÃO'
        },
        { VariavelNo: 3492, ValorDado: patient.seSimQual2 || '' },
        { VariavelNo: 3911, ValorDado: patient.nomepai || '' },
        { VariavelNo: 28065, ValorDado: patient.situacaoPaciente ? 'ATIVO' : 'INATIVO' },
        { VariavelNo: 28660, ValorDado: patient.pacienteFalecido ? 'SIM' : 'NÃO' },
        { VariavelNo: 28661, ValorDado: this.glb.ddMMYYYYThhmmssToDate(patient.dataObito) },
        { VariavelNo: 28662, ValorDado: patient.anoObito || '' },
        { VariavelNo: 28755, ValorDado: patient.usaFralda ? 'SIM' : 'NÃO' },
        { VariavelNo: 28756, ValorDado: patient.comeSozinho ? 'SIM' : 'NÃO' },
        { VariavelNo: 28757, ValorDado: patient.cadeirante ? 'SIM' : 'NÃO' },
        { VariavelNo: 28758, ValorDado: patient.traqueostomizado ? 'SIM' : 'NÃO' },
        { VariavelNo: 28759, ValorDado: patient.ulceradecubito ? 'SIM' : 'NÃO' },
        { VariavelNo: 28760, ValorDado: patient.sondaGastrica ? 'SIM' : 'NÃO' },
        { VariavelNo: 28761, ValorDado: patient.sondaNaso ? 'SIM' : 'NÃO' },
        { VariavelNo: 28762, ValorDado: patient.comUlceraPosHospitalizacao ? 'SIM' : 'NÃO' },
        { VariavelNo: 28763, ValorDado: patient.hospital || '' },
        { VariavelNo: 28764, ValorDado: patient.fraturaColofemur || '' },
        { VariavelNo: 28765, ValorDado: patient.diabetico ? 'SIM' : 'NÃO' },
        { VariavelNo: 28766, ValorDado: patient.usaInsulina ? 'SIM' : 'NÃO' },
        { VariavelNo: 109167, ValorDado: patient.lilas ? 'SIM' : 'NÃO' },
        { VariavelNo: 109168, ValorDado: patient.obito ? 'SIM' : 'NÃO' },
        { VariavelNo: 28767, ValorDado: patient.menores60 || '' },
        { VariavelNo: 28768, ValorDado: this.glb.ddMMYYYYThhmmssToDate(patient.dataAdmissao) },
        { VariavelNo: 28769, ValorDado: patient.anoAdmissao || '' },
        { VariavelNo: 28770, ValorDado: patient.admissaoAntes2000 || '' },
        { VariavelNo: 28774, ValorDado: patient.hospitalSim || '' },
        { VariavelNo: 28868, ValorDado: patient.agendamento || '' },
        { VariavelNo: 28953, ValorDado: patient.unidade || '' },
        { VariavelNo: 28954, ValorDado: patient.urlLogo || '' },
        { VariavelNo: 28958, ValorDado: patient.prontuarioSoma || '' },
        { VariavelNo: 28959, ValorDado: patient.filtro || '' },
        { VariavelNo: 28960, ValorDado: patient.totalCadastro || '' },
        { VariavelNo: 28961, ValorDado: patient.prontuarioFinalCalc || '' },
        { VariavelNo: 28970, ValorDado: patient.pRTO || '' },
        { VariavelNo: 28982, ValorDado: patient.identificacoes || '' },
        { VariavelNo: 29006, ValorDado: patient.endereco || '' },
        { VariavelNo: 29007, ValorDado: patient.numero || '' },
        { VariavelNo: 29008, ValorDado: patient.bairro || '' },
        { VariavelNo: 29009, ValorDado: patient.cidade || '' },
        { VariavelNo: 29010, ValorDado: patient.telefone2 || '' },
        { VariavelNo: 29011, ValorDado: patient.estado2 || '' },
        { VariavelNo: 29012, ValorDado: patient.celular || '' },
        { VariavelNo: 29013, ValorDado: patient.eMail || '' },
        { VariavelNo: 29065, ValorDado: patient.quarto || '' },
        { VariavelNo: 29197, ValorDado: patient.regiao || '' },
        { VariavelNo: 29293, ValorDado: patient.trato || '' },
        { VariavelNo: 29294, ValorDado: patient.educacao || '' },
        { VariavelNo: 3304, ValorDado: patient.foto || '' },
        { VariavelNo: 29792, ValorDado: patient.referencia || '' },
        { VariavelNo: 29793, ValorDado: patient.perfilCliente || '' },
        { VariavelNo: 29818, ValorDado: patient.informacoesImportante || '' },
        { VariavelNo: 1259, ValorDado: patient.diagnostico || '' },
        { VariavelNo: 757, ValorDado: patient.pesoAtual || '' },
        { VariavelNo: 762, ValorDado: patient.altura || '' },
        { VariavelNo: 764, ValorDado: patient.imc || '' },
        { VariavelNo: 28051, ValorDado: patient.prontuario || '' }
      ];
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapToForm', error.message);
    }
    return null;
  }

  /*  Retorna uma lista com todos os pacientes e seus respectivos medicamentos
   * Atenção: A frequencia não será um campo. Caso esteja prescrito duas vezes ao dia, deverão ser criados dois registros por dia. E assim, sucessivamente.
   */
  /*  getAll(): Array<IPatient> {
      try {
        return [
          <IPatient>{
            patientId: 1,
            patientName: "Albert Einstein",
            birthDate: new Date(1940, 1, 17),
            medications: [
              <IMedication>{
                medicationId: 22,
                medicationName: 'Rosuvastatina',
                prescription: "Em caso de dor",
                dosage: "10 mg",
                took: false,
                prescribedTime: "-",
              },
              <IMedication>{
                medicationId: 20,
                medicationName: 'Aspirina',
                prescription: "Uma vez ao dia",
                dosage: "5 mg",
                took: false,
                prescribedTime: "08:00",
              },
            ]
          },
          <IPatient>{
            patientId: 2,
            patientName: "Cora Coralina",
            birthDate: new Date(1932, 3, 4),
            medications: [
              <IMedication>{
                medicationId: 22,
                medicationName: 'Rosuvastatina',
                prescription: "Uma vez ao dia",
                dosage: "10 mg",
                took: false,
                prescribedTime: "08:00",
              },
              <IMedication>{
                medicationId: 20,
                medicationName: 'Aspirina',
                prescription: "Uma vez ao dia",
                dosage: "5 mg",
                took: false,
                prescribedTime: "08:00",
              },
            ]
          },
          <IPatient>{
            patientId: 1,
            patientName: "Anita Malfati",
            birthDate: new Date(1960, 1, 22),
            medications: [
              <IMedication>{
                medicationId: 22,
                medicationName: 'Rosuvastatina',
                prescription: "Uma vez ao dia",
                dosage: "10 mg",
                took: false,
                prescribedTime: "08:00",
              },
              <IMedication>{
                medicationId: 20,
                medicationName: 'Aspirina',
                prescription: "Uma vez ao dia",
                dosage: "5 mg",
                took: true,
                prescribedTime: "13:00",
              },
            ]
          },
          <IPatient>{
            patientId: 2,
            patientName: "Martin Lutero",
            birthDate: new Date(1922, 3, 1),
            medications: [
              <IMedication>{
                medicationId: 22,
                medicationName: 'Rosuvastatina',
                prescription: "Uma vez ao dia",
                dosage: "10 mg",
                took: false,
                prescribedTime: "08:00",
              },
              <IMedication>{
                medicationId: 20,
                medicationName: 'Aspirina',
                prescription: "Uma vez ao dia",
                dosage: "5 mg",
                took: false,
                prescribedTime: "08:00",
              },
              <IMedication>{
                medicationId: 22,
                medicationName: 'Rosuvastatina',
                prescription: "Uma vez ao dia",
                dosage: "10 mg",
                took: true,
                prescribedTime: "13:00",
              },
              <IMedication>{
                medicationId: 20,
                medicationName: 'Aspirina',
                prescription: "Uma vez ao dia",
                dosage: "5 mg",
                took: false,
                prescribedTime: "08:00",
              },
            ]
          },
          <IPatient>{
            patientId: 1,
            patientName: "Albert Einstein",
            birthDate: new Date(1940, 1, 17),
            medications: [
              <IMedication>{
                medicationId: 22,
                medicationName: 'Rosuvastatina',
                prescription: "Uma vez ao dia",
                dosage: "10 mg",
                took: false,
                prescribedTime: "08:00",
              },
              <IMedication>{
                medicationId: 20,
                medicationName: 'Aspirina',
                prescription: "Uma vez ao dia",
                dosage: "5 mg",
                took: false,
                prescribedTime: "08:00",
              },
            ]
          },
          <IPatient>{
            patientId: 2,
            patientName: "Cora Coralina",
            birthDate: new Date(1932, 3, 4),
            medications: [
              <IMedication>{
                medicationId: 22,
                medicationName: 'Rosuvastatina',
                prescription: "Uma vez ao dia",
                dosage: "10 mg",
                took: false,
                prescribedTime: "08:00",
              },
              <IMedication>{
                medicationId: 20,
                medicationName: 'Aspirina',
                prescription: "Uma vez ao dia",
                dosage: "5 mg",
                took: false,
                prescribedTime: "08:00",
              },
            ]
          },
          <IPatient>{
            patientId: 1,
            patientName: "Anita Malfati",
            birthDate: new Date(1960, 1, 22),
            medications: [
              <IMedication>{
                medicationId: 22,
                medicationName: 'Rosuvastatina',
                prescription: "Uma vez ao dia",
                dosage: "10 mg",
                took: false,
                prescribedTime: "08:00",
              },
              <IMedication>{
                medicationId: 20,
                medicationName: 'Aspirina',
                prescription: "Uma vez ao dia",
                dosage: "5 mg",
                took: true,
                prescribedTime: "13:00",
              },
            ]
          },
          <IPatient>{
            patientId: 2,
            patientName: "Martin Lutero",
            birthDate: new Date(1922, 3, 1),
            medications: [
              <IMedication>{
                medicationId: 22,
                medicationName: 'Rosuvastatina',
                prescription: "Uma vez ao dia",
                dosage: "10 mg",
                took: false,
                prescribedTime: "08:00",
              },
              <IMedication>{
                medicationId: 20,
                medicationName: 'Aspirina',
                prescription: "Uma vez ao dia",
                dosage: "5 mg",
                took: false,
                prescribedTime: "08:00",
              },
              <IMedication>{
                medicationId: 22,
                medicationName: 'Rosuvastatina',
                prescription: "Uma vez ao dia",
                dosage: "10 mg",
                took: true,
                prescribedTime: "13:00",
              },
              <IMedication>{
                medicationId: 20,
                medicationName: 'Aspirina',
                prescription: "Uma vez ao dia",
                dosage: "5 mg",
                took: false,
                prescribedTime: "08:00",
              },
            ]
          },
          <IPatient>{
            patientId: 1,
            patientName: "Albert Einstein",
            birthDate: new Date(1940, 1, 17),
            medications: [
              <IMedication>{
                medicationId: 22,
                medicationName: 'Rosuvastatina',
                prescription: "Uma vez ao dia",
                dosage: "10 mg",
                took: false,
                prescribedTime: "11:00",
              },
              <IMedication>{
                medicationId: 20,
                medicationName: 'Aspirina',
                prescription: "Uma vez ao dia",
                dosage: "5 mg",
                took: false,
                prescribedTime: "13:00",
              },
            ]
          },
          <IPatient>{
            patientId: 2,
            patientName: "Martin Lutero",
            birthDate: new Date(1922, 3, 1),
            medications: [
              <IMedication>{
                medicationId: 22,
                medicationName: 'Rosuvastatina',
                prescription: "Uma vez ao dia",
                dosage: "10 mg",
                took: false,
                prescribedTime: "18:00",
              },
              <IMedication>{
                medicationId: 20,
                medicationName: 'Aspirina',
                prescription: "Uma vez ao dia",
                dosage: "5 mg",
                took: false,
                prescribedTime: "08:00",
              },
              <IMedication>{
                medicationId: 22,
                medicationName: 'Rosuvastatina',
                prescription: "Uma vez ao dia",
                dosage: "10 mg",
                took: true,
                prescribedTime: "13:00",
              },
              <IMedication>{
                medicationId: 20,
                medicationName: 'Aspirina',
                prescription: "Uma vez ao dia",
                dosage: "5 mg",
                took: false,
                prescribedTime: "18:00",
              },
              <IMedication>{
                medicationId: 22,
                medicationName: 'Rosuvastatina',
                prescription: "Uma vez ao dia",
                dosage: "10 mg",
                took: false,
                prescribedTime: "08:00",
              },
              <IMedication>{
                medicationId: 20,
                medicationName: 'Aspirina',
                prescription: "Uma vez ao dia",
                dosage: "5 mg",
                took: false,
                prescribedTime: "18:00",
              },
              <IMedication>{
                medicationId: 22,
                medicationName: 'Rosuvastatina',
                prescription: "Uma vez ao dia",
                dosage: "10 mg",
                took: true,
                prescribedTime: "13:00",
              },
              <IMedication>{
                medicationId: 20,
                medicationName: 'Aspirina',
                prescription: "Uma vez ao dia",
                dosage: "5 mg",
                took: false,
                prescribedTime: "10:00",
              },
            ]
          },
          <IPatient>{
            patientId: 2,
            patientName: "Cora Coralina",
            birthDate: new Date(1932, 3, 4),
            medications: [
              <IMedication>{
                medicationId: 22,
                medicationName: 'Rosuvastatina',
                prescription: "Uma vez ao dia",
                dosage: "10 mg",
                took: false,
                prescribedTime: "10:00",
              },
              <IMedication>{
                medicationId: 20,
                medicationName: 'Aspirina',
                prescription: "Uma vez ao dia",
                dosage: "5 mg",
                took: false,
                prescribedTime: "08:00",
              },
            ]
          },
          <IPatient>{
            patientId: 1,
            patientName: "Anita Malfati",
            birthDate: new Date(1960, 1, 22),
            medications: [
              <IMedication>{
                medicationId: 22,
                medicationName: 'Rosuvastatina',
                prescription: "Uma vez ao dia",
                dosage: "10 mg",
                took: false,
                prescribedTime: "13:00",
              },
              <IMedication>{
                medicationId: 20,
                medicationName: 'Aspirina',
                prescription: "Uma vez ao dia",
                dosage: "5 mg",
                took: true,
                prescribedTime: "13:00",
              },
            ]
          },
        ];
      } catch (error) {
        this.log.Registrar(this.constructor.name, 'getAll', error.message);
      }
    }*/
}
